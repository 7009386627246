export function SetFavicon(location) {
  var link = document.querySelector("link[rel*='icon']") || document.createElement('link');
  link.type = 'image/x-icon';
  link.rel = 'shortcut icon';
  link.href = location || require("favicon.ico");
  document.getElementsByTagName('head')[0].appendChild(link);
}

export function rand(min, max) {
  return Math.round(randFloat(min, max));
}

export function randFloat(min, max) {
  return min + Math.random() * (max - min);
}

//https://stackoverflow.com/questions/6274339/how-can-i-shuffle-an-array
export function shuffleArray(a) {
  for (let i = a.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [a[i], a[j]] = [a[j], a[i]];
  }
  return a;
}