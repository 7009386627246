import React, { Component } from 'react';
//import { FilterListRounded, ExpandMoreRounded } from '@mui/icons-material';

import style from './style.module.scss';

export default class FowlFigures extends Component {
  constructor(props) {
    super(props);
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.state = {
      datetimeNow: new Date(Date.now()).toLocaleString("en-US", {timeZone, year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric'}),
    };
  }

  componentDidMount() {
    setInterval(() => {
      const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      this.setState({
        datetimeNow: new Date(Date.now()).toLocaleString("en-US", {timeZone, year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric'}),
      });
    }, 1000);
  }

  render() {
    const { datetimeNow } = this.state;
    
    /*
    - Choose from all data or by each chicken

    BIG NUMS
      - num of eggs
      - num of days
      - num of chickens
      - num of chickens in coop / in roost / outside

    TABLES
      - eggs by day of week & time
      - eggs by roosts

    GRAPHS
      - eggs over time (default zoom is this week)
      - bedtime over time (default zoom is this week)
    */

    /*const menu = (
      <Menu>
        <Menu.Item key="all" icon={<ExpandMoreRounded />}>
          Include all
        </Menu.Item>
        <Menu.Divider />
          By breed:
        <Menu.Item key="breedBlack" icon={<ExpandMoreRounded />}>
          Plymouth Rock (6)
        </Menu.Item>
        <Menu.Item key="breedBrown" icon={<FilterListRounded />}>
          Rhode Island Red (2)
        </Menu.Item>
        <Menu.Divider/>
        By chicken:
        <Menu.Item key="chicken1" icon={<FilterListRounded />}>
          Chicken 1
        </Menu.Item>
        <Menu.Item key="chicken2" icon={<FilterListRounded />}>
          Chicken 2
        </Menu.Item>
        <Menu.Item key="chicken3" icon={<FilterListRounded />}>
          Chicken 3
        </Menu.Item>
        <Menu.Item key="chicken4" icon={<FilterListRounded />}>
          Chicken 4
        </Menu.Item>
        <Menu.Item key="chicken5" icon={<FilterListRounded />}>
          Chicken 5
        </Menu.Item>
        <Menu.Item key="chicken6" icon={<FilterListRounded />}>
          Chicken 6
        </Menu.Item>
        <Menu.Item key="chicken7" icon={<FilterListRounded />}>
          Chicken 7
        </Menu.Item>
        <Menu.Item key="chicken8" icon={<FilterListRounded />}>
          Chicken 8
        </Menu.Item>
      </Menu>
    );*/

    return (
      <div className={style.fullContainer}>
        <div className={style.container}>
          <div className={style.header}>
            <h1 className={style.title}>Fowl Figures</h1>
            <h2 className={style.datenow}>{datetimeNow}</h2>
          </div>
          <hr className={style.hr}/>
          {/*<div className={style.sectionSort}>
            <Dropdown 
              overlay={menu}
              className={style.sortDropdown}
            >
              <Button>
                <FilterListRounded/> Sort by <ExpandMoreRounded/>
              </Button>
            </Dropdown>
          </div>*/}
        </div>
      </div>
    );
  }
};