const categories = [
  {
    "Name": "Clothing",
    "Items": [
      "Dress",
      "Gown",
      "Skirt",
      "Jumpsuit",
      "Kilt",
      "Pajamas",
      "Shorts",
      "Suit",
      "Swimsuit",
      "Bikini",
      "Briefs",
      "Tank top",
      "T-shirt",
      "Vest",
      "Jeans",
      "Leggings"
    ]
  },
  {
    "Name": "Footwear",
    "Items": [
      "Shoe",
      "Boot",
      "Cleat",
      "Sandal",
      "Slipper",
      "Sneakers",
      "Snowshoe",
      "High heels",
      "Hiking boot",
      "Ballet flat",
      "Moccasin",
      "Flip flops",
      "Cowboy boot",
      "Chaco",
      "Loafer",
      "Velcro"
    ]
  },
  {
    "Name": "Headgear",
    "Items": [
      "Crown",
      "Tiara",
      "Hat",
      "Beret",
      "Baseball cap",
      "Beanie",
      "Cowboy hat",
      "Fedora",
      "Fez",
      "Sombrero",
      "Top hat",
      "Bonnet",
      "Ushanka (Russian hat)",
      "Helmet",
      "Headband",
      "Veil"
    ]
  },
  {
    "Name": "Outerwear",
    "Items": [
      "Apron",
      "Cape",
      "Cloak",
      "Coat",
      "Glove",
      "Handkerchief",
      "Turban",
      "Hijab",
      "Jacket",
      "Blazer",
      "Poncho",
      "Shawl",
      "Scarf",
      "Sweater",
      "Hoodie",
      "Parka"
    ]
  },
  {
    "Name": "Underwear",
    "Items": [
      "Socks",
      "Bra",
      "Briefs",
      "Boxers",
      "Camisole",
      "Corset",
      "Diaper",
      "Lingerie",
      "Panties",
      "Petticoat",
      "Thong",
      "G-string",
      "Undershirt",
      "Garments",
      "Long johns",
      "Jockstrap"
    ]
  },
  {
    "Name": "Accessories",
    "Items": [
      "Backpack",
      "Badge",
      "Belt",
      "Choker",
      "Girdle",
      "Handbag",
      "Necktie",
      "Ascot",
      "Bow tie",
      "Scarf",
      "Scrunchie",
      "Sunglasses",
      "Suspenders",
      "Umbrella",
      "Wallet",
      "Zipper"
    ]
  },
  {
    "Name": "Hairstyles",
    "Items": [
      "Afro",
      "Bowl cut",
      "Braid",
      "Buzz cut",
      "Crew cut",
      "Comb over",
      "Dreadlocks",
      "Perm",
      "Ponytail",
      "Mohawk",
      "Bald (none)",
      "Pixie cut",
      "Mullet",
      "Bun",
      "Dreadlocks",
      "Frosted tips"
    ]
  },
  {
    "Name": "Food Service",
    "Items": [
      "Bar",
      "Cigar bar",
      "Dive bar",
      "Snack bar",
      "Pub",
      "Restaurant",
      "Fast food",
      "Drive-thru",
      "Food court",
      "Street food",
      "Food cart",
      "Food truck",
      "Ice cream van",
      "Café",
      "Buffet",
      "Market"
    ]
  },
  {
    "Name": "Food Prep Methods",
    "Items": [
      "Pickling",
      "Smoking",
      "Barbecuing",
      "Brewing",
      "Canning",
      "Cooking",
      "Baking",
      "Deep frying",
      "Stir frying",
      "Grilling",
      "Poaching",
      "Pressure cooking",
      "Roasting",
      "Sautéing",
      "Searing",
      "Steaming"
    ]
  },
  {
    "Name": "Cooking Oils",
    "Items": [
      "Vegetable oil",
      "Avocado oil",
      "Canola oil",
      "Coconut oil",
      "Corn oil",
      "Cottonseed oil",
      "Olive oil",
      "Palm oil",
      "Peanut oil",
      "Seasame oil",
      "Sunflower oil",
      "Butter",
      "Lard",
      "Shortening",
      "Soybean oil",
      "Margarine"
    ]
  },
  {
    "Name": "Grain-based",
    "Items": [
      "Baguette",
      "Bread crumbs",
      "Bun",
      "Croissant",
      "Flatbread",
      "Naan",
      "Pita",
      "Tortilla",
      "Garlic bread",
      "Cornbread",
      "Rye bread",
      "Toast",
      "French toast",
      "Flour",
      "Cornmeal",
      "Whole grain"
    ]
  },
  {
    "Name": "Edible Animal Products",
    "Items": [
      "Egg",
      "Milk",
      "Cheese",
      "Honey",
      "Lard",
      "Beef",
      "Veal",
      "Cat meat",
      "Dog meat",
      "Goat meat",
      "Horse meat",
      "Lamb",
      "Pork",
      "Chicken",
      "Duck",
      "Turkey"
    ]
  },
  {
    "Name": "Dairy",
    "Items": [
      "Milk",
      "Buttermilk",
      "Chocolate milk",
      "Butter",
      "Cream",
      "Sour cream",
      "Whipped cream",
      "Custard",
      "Margarine",
      "Cheese",
      "Ice cream",
      "Gelato",
      "Soft serve",
      "Soy milk",
      "Tofu",
      "Yogurt"
    ]
  },
  {
    "Name": "Condiments",
    "Items": [
      "BBQ sauce",
      "Jam/Jelly",
      "Gravy",
      "Guacamole",
      "Hot sauce",
      "Ketchup",
      "Mayonnaise",
      "Mustard",
      "Pesto",
      "Salad dressing",
      "Salsa",
      "Salt",
      "Soy sauce",
      "Sugar",
      "Syrup",
      "Pepper"
    ]
  },
  {
    "Name": "Food Types",
    "Items": [
      "Cereal",
      "Burrito",
      "Cake",
      "Candy",
      "Candy bar",
      "Casserole",
      "Chewing gum",
      "Chocolate",
      "Chowder",
      "Cookie",
      "Curry",
      "Doughnut",
      "Empanada",
      "Enchilada",
      "Fries",
      "Kebab"
    ]
  },
  {
    "Name": "Food Types 2",
    "Items": [
      "Pasta",
      "Pancake",
      "Pastry",
      "Pie",
      "Pizza",
      "Popcorn",
      "Porridge",
      "Pudding",
      "Salad",
      "Sandwich",
      "Soup",
      "Stew",
      "Sushi",
      "Taco",
      "Tart",
      "Waffle"
    ]
  },
  {
    "Name": "Cold Drinks",
    "Items": [
      "Tap water",
      "Bottled water",
      "Carbonated water",
      "Iced coffee",
      "Iced tea",
      "Apple juice",
      "Cranberry juice",
      "Grape juice",
      "Orange juice",
      "Tomato juice",
      "Milkshake",
      "Soft drink",
      "Energy drink",
      "Ginger ale",
      "Lemonade",
      "Sports drink"
    ]
  },
  {
    "Name": "Hot Drinks",
    "Items": [
      "Coffee",
      "Cappuccino",
      "Espresso",
      "Latte",
      "Hot chocolate",
      "Green tea",
      "Black tea",
      "Herbal tea",
      "Chamomile",
      "Hibiscus tea",
      "Apple cider",
      "Bouillon soup",
      "Postum",
      "Sake",
      "Warm milk",
      "Hot Kool-Aid™"
    ]
  },
  {
    "Name": "Alcohol",
    "Items": [
      "Beer",
      "Wine",
      "Champagne",
      "Cider",
      "Cocktail",
      "Martini",
      "Margarita",
      "Mint julep",
      "Brandy",
      "Gin",
      "Rum",
      "Tequila",
      "Vodka",
      "Whisky",
      "Bourbon",
      "Moonshine"
    ]
  },
  {
    "Name": "Family Members",
    "Items": [
      "Husband",
      "Wife",
      "Pet",
      "Father",
      "Mother",
      "Son",
      "Daughter",
      "Brother",
      "Sister",
      "Grandma",
      "Grandpa",
      "Aunt",
      "Uncle",
      "Niece",
      "Nephew",
      "Cousin"
    ]
  },
  {
    "Name": "Furniture",
    "Items": [
      "Bathtub",
      "Bed",
      "Bunk bed",
      "Futon",
      "Hammock",
      "Headboard",
      "Mattress",
      "Bookcase",
      "Cabinets",
      "Carpet",
      "Closet",
      "Wardrobe",
      "Coat rack",
      "Counter",
      "Cupboard",
      "Curtain"
    ]
  },
  {
    "Name": "Furniture 2",
    "Items": [
      "Desk",
      "Fireplace",
      "Lamp",
      "Ottoman",
      "Pillow",
      "Bench",
      "Chair",
      "Recliner",
      "Couch",
      "Stool",
      "Shelf",
      "Shower",
      "Dinner table",
      "Coffee table",
      "Nightstand",
      "Vase"
    ]
  },
  {
    "Name": "Cutlery",
    "Items": [
      "Chopsticks",
      "Butter knife",
      "Fork",
      "Spoon",
      "Spork",
      "Steak knife",
      "Drinking straw",
      "Skewer",
      "Cup",
      "Coffee cup",
      "Jar",
      "Mug",
      "Plate",
      "Salt and pepper shakers",
      "Teapot",
      "Wine glass"
    ]
  },
  {
    "Name": "National and State Parks",
    "Items": [
      "Antelope Island",
      "Arches",
      "Bear Lake",
      "Bryce Canyon",
      "Central Park",
      "Canyonlands",
      "Capitol Reef",
      "Goblin Valley",
      "Death Valley",
      "Grand Canyon",
      "Grand Teton",
      "Mesa Verde",
      "Redwood",
      "Yellowstone",
      "Yosemite",
      "Zion"
    ]
  },
  {
    "Name": "City Areas",
    "Items": [
      "Block",
      "Downtown",
      "City centre",
      "Chinatown",
      "Ghetto",
      "Metropolis",
      "Megalopolis",
      "Neighborhood",
      "Rural area",
      "Slum",
      "Suburb",
      "Town square",
      "Village",
      "Business park",
      "Residential area",
      "Industrial park"
    ]
  },
  {
    "Name": "US Cities",
    "Items": [
      "Atlantic City",
      "Baltimore",
      "Boston",
      "New York City",
      "Jersey City",
      "Philadelphia",
      "Pittsburgh",
      "Washington, D.C.",
      "Atlanta",
      "Dallas",
      "Houston",
      "Jacksonville",
      "Memphis",
      "Miami",
      "Nashville",
      "New Orleans"
    ]
  },
  {
    "Name": "",
    "Items": [
      "Orlando",
      "Chicago",
      "Kansas City",
      "Boise",
      "Denver",
      "Honolulu",
      "Las Vegas",
      "Los Angeles",
      "Anaheim",
      "Hollywood",
      "Phoenix",
      "Portland",
      "Salt Lake City",
      "San Diego",
      "San Francisco",
      "Seattle"
    ]
  },
  {
    "Name": "Fiction Genres",
    "Items": [
      "Romance",
      "Historical",
      "Political",
      "Satire",
      "Dark humor",
      "Sports",
      "War",
      "Western",
      "Young adult",
      "Crime",
      "Mystery",
      "Thriller",
      "Fantasy",
      "Horror",
      "Science",
      "Superhero"
    ]
  },
  {
    "Name": "Non-Fiction Genres",
    "Items": [
      "Autobiography",
      "Biography",
      "Coloring book",
      "Cookbook",
      "Diary",
      "Essay",
      "Glossary",
      "Guide book",
      "Journalism",
      "Memoir",
      "Self-help",
      "Almanac",
      "Bibliography",
      "Dictionary",
      "Encyclopedia",
      "Thesaurus"
    ]
  },
  {
    "Name": "Narrative Keywords",
    "Items": [
      "Character",
      "Antagonist",
      "Antihero",
      "Character arc",
      "Characterization",
      "Protagonist",
      "Supporting character",
      "Continuity",
      "Story structure",
      "Plot",
      "Hero's journey",
      "Story arc",
      "Narration",
      "Setting",
      "Theme",
      "Writing style"
    ]
  },
  {
    "Name": "Fiction Books",
    "Items": [
      "Harry Potter",
      "The Lord of the Rings",
      "The Chronicles of Narnia",
      "A Christmas Carol",
      "The Wonderful Wizard of Oz",
      "To Kill a Mockingbird",
      "The Jungle Book",
      "Les Misérables",
      "1984",
      "Fahrenheit 451",
      "Moby-Dick",
      "Pride and Prejudice",
      "A Wrinkle in Time",
      "The Princess Bride",
      "The Ugly Duckling",
      "Uncle Tom's Cabin"
    ]
  },
  {
    "Name": "Children's Books",
    "Items": [
      "Berenstain Bears",
      "The Cat in the Hat",
      "Charlie and the Chocolate Factory",
      "Charlotte's Web",
      "Clifford the Big Red Dog",
      "Curious George",
      "Goosebumps",
      "Green Eggs and Ham",
      "Horton Hears a Who!",
      "The Magic School Bus",
      "Matilda",
      "The Very Hungry Caterpillar",
      "Where the Wild Things Are",
      "Where's Waldo?",
      "Winnie-the-Pooh",
      "Chicka Chicka Boom Boom"
    ]
  },
  {
    "Name": "Musical Notation",
    "Items": [
      "Arrangement",
      "Bar",
      "Clef",
      "Dynamics",
      "Flat",
      "Key signature",
      "Major and minor",
      "Note",
      "Sharp",
      "Sheet music",
      "Staff",
      "Time signature",
      "Measure",
      "Rest",
      "Natural",
      "Beats per minute"
    ]
  },
  {
    "Name": "Music Theory",
    "Items": [
      "Articulation",
      "Chord",
      "Chord progression",
      "Circle of fifths",
      "Harmony",
      "Interval",
      "Melody",
      "Arpeggio",
      "Motif",
      "Pitch",
      "Bass",
      "Rhythm",
      "Meter",
      "Major scale",
      "Minor scale",
      "Key"
    ]
  },
  {
    "Name": "Music Genres",
    "Items": [
      "Dance",
      "Disco",
      "Funk",
      "Indie",
      "Psychedelic",
      "Pop",
      "Boy band",
      "K-pop",
      "New wave",
      "Synth-pop",
      "Reggae",
      "Salsa",
      "Ska",
      "Soundtrack",
      "Blues",
      "Contemporary R&B"
    ]
  },
  {
    "Name": "Music Genres 2",
    "Items": [
      "Soul",
      "Electronic",
      "Dubstep",
      "House",
      "Techno",
      "Bluegrass",
      "Country",
      "Folk",
      "Hip hop",
      "Gangsta rap",
      "Pop rap",
      "Trap",
      "Jazz",
      "Big band",
      "Bossa nova",
      "Swing"
    ]
  },
  {
    "Name": "Rock Sub-Genres",
    "Items": [
      "Alternative rock",
      "Britpop",
      "Grunge",
      "Gothic rock",
      "Heavy metal",
      "Death metal",
      "Pop rock",
      "Punk rock",
      "Emo",
      "Surf",
      "Rock and roll",
      "Indie rock",
      "Southern rock",
      "Classic rock",
      "Electric rock",
      "Psychedelic rock"
    ]
  },
  {
    "Name": "Percussion Instruments",
    "Items": [
      "Bass drum",
      "Bell",
      "Bongos",
      "Steel drums",
      "Glockenspiel (bells)",
      "Crash cymbal",
      "Drumset",
      "Snare drum",
      "Timpani",
      "Triangle",
      "Chimes",
      "Vibraphone",
      "Xylophone",
      "Marimba",
      "Tambourine",
      "Shaker"
    ]
  },
  {
    "Name": "String Instruments",
    "Items": [
      "Erhu",
      "Banjo",
      "Cello",
      "Double bass (Upright bass)",
      "Electric bass",
      "Fiddle",
      "Guitar",
      "Harp",
      "Harpsichord",
      "Mandolin",
      "Piano",
      "Sitar",
      "Ukulele",
      "Viola",
      "Violin",
      "Electric guitar"
    ]
  },
  {
    "Name": "Wind instruments",
    "Items": [
      "Accordion",
      "Bagpipes",
      "Bugle",
      "French horn",
      "Trombone",
      "Trumpet",
      "Tuba",
      "Harmonica",
      "Organ",
      "Bassoon",
      "Clarinet",
      "Flute",
      "Piccolo",
      "Recorder",
      "Oboe",
      "Saxophone"
    ]
  },
  {
    "Name": "Traditional music",
    "Items": [
      "99 Bottles of Beer",
      "La Cucaracha",
      "For He's a Jolly Good Fellow",
      "Frère Jacques",
      "Happy Birthday to You",
      "Twinkle, Twinkle, Little Star",
      "Yankee Doodle",
      "Amazing Grace",
      "Deck the Halls",
      "God Rest Ye Merry Gentlemen",
      "God Save the Queen",
      "London Bridge is Falling Down",
      "My Country, 'Tis of Thee",
      "O Come All Ye Faithful",
      "Rockabye Baby",
      "What Shall We Do with the Drunken Sailor?"
    ]
  },
  {
    "Name": "Christmas Songs",
    "Items": [
      "Away in a Manger",
      "Carol of the Bells",
      "Deck the Halls",
      "Good King Wenceslas",
      "Joy to the World",
      "Jingle Bells",
      "Silent Night",
      "We Wish You a Merry Christmas",
      "God Rest Ye Merry Gentlemen",
      "Oh, Come, All Ye Faithful",
      "Angels We Have Heard on High",
      "Once in Royal David's City",
      "O Little Town of Bethlehem",
      "Hark! The Herald Angels Sing",
      "Far, Far Away on Judea's Plains",
      "The First Noel"
    ]
  },
  {
    "Name": "Musicals",
    "Items": [
      "Cats",
      "Fiddler on the Roof",
      "Hamilton",
      "Hairspray",
      "The Book of Mormon",
      "Les Misérables",
      "The Lion King",
      "The Phantom of the Opera",
      "West Side Story",
      "Wicked",
      "Urinetown",
      "The Sound of Music",
      "Mamma Mia!",
      "Beauty and the Beast",
      "Grease",
      "Annie"
    ]
  },
  {
    "Name": "Modern Music Artists",
    "Items": [
      "Ed Sheeran",
      "Drake",
      "Nicki Minaj",
      "Sia",
      "Nicki Minaj",
      "Taylor Swift",
      "Justin Bieber",
      "Eminem",
      "Beyoncé",
      "Adele",
      "Lady Gaga",
      "Bruno Mars",
      "Maroon 5",
      "Billie Eilish",
      "Rihanna",
      "Katy Perry"
    ]
  },
  {
    "Name": "80's Songs",
    "Items": [
      "Take on Me",
      "Africa",
      "Tainted Love",
      "Total Eclipse of the Heart",
      "Don't Stop Believin'",
      "Summer of '69",
      "Under Pressure",
      "Everybody Wants to Rule the World",
      "Sweet Dreams (Are Made of This)",
      "You Give Love a Bad Name",
      "We Built this City",
      "Never Gonna Give You Up",
      "Every Breath You Take",
      "The Final Countdown",
      "(I Just) Died in Your Arms",
      "Love is a Battlefield"
    ]
  },
  {
    "Name": "Dance Moves",
    "Items": [
      "Ballroom dance",
      "Foxtrot",
      "Jive",
      "Quickstep",
      "Tango",
      "Waltz",
      "Belly dance",
      "Cha-cha-cha",
      "Mambo",
      "Salsa",
      "Samba",
      "Box step",
      "Floss",
      "Breakdance",
      "Harlem shake",
      "Jitterbug"
    ]
  },
  {
    "Name": "Art Forms",
    "Items": [
      "Visual arts",
      "Body art",
      "Bonsai",
      "Wood carving",
      "Craft",
      "Decorative arts",
      "Drawing",
      "Figurative art",
      "Fine art",
      "Folk art",
      "Glass art",
      "Grotesque",
      "Handicraft",
      "Illustration",
      "Mosaic",
      "Mural"
    ]
  },
  {
    "Name": "Art Forms 2",
    "Items": [
      "Optical illusion",
      "Panorama",
      "Perspective",
      "Portrait",
      "Printmaking",
      "Stone carving",
      "Engraving",
      "Etching",
      "Religious art",
      "Sandpainting",
      "Stained glass",
      "Fashion",
      "Steampunk",
      "Taxidermy",
      "Textile arts",
      "Beadwork"
    ]
  },
  {
    "Name": "Design Categories",
    "Items": [
      "Building",
      "Fashion",
      "Floral",
      "Game",
      "Garden",
      "Graphic",
      "Interior",
      "Industrial",
      "Automotive",
      "Product",
      "Landscape",
      "Motion graphic",
      "Systems",
      "User experience",
      "User interface",
      "Web"
    ]
  },
  {
    "Name": "Animated Films",
    "Items": [
      "Beauty and the Beast",
      "The Lion King",
      "Snow White and the Seven Dwarfs",
      "Steamboat Willie",
      "Toy Story",
      "Inside Out",
      "Coco",
      "Incredibles",
      "Finding Nemo",
      "Pinocchio",
      "The LEGO Movie",
      "How to Train Your Dragon",
      "Dumbo",
      "The Nightmare Before Christmas",
      "Aladdin",
      "Monsters, Inc."
    ]
  },
  {
    "Name": "Classic Films",
    "Items": [
      "Citizen Kane",
      "The Godfather",
      "It's a Wonderful Life",
      "Mary Poppins",
      "Jaws",
      "King Kong",
      "North by Northwest",
      "Psycho",
      "Rear Window",
      "The Shining",
      "The Sound of Music",
      "Vertigo",
      "2001: A Space Odyssey",
      "Arsenic and Old Lace",
      "Raiders of the Lost Ark",
      "The Wizard of Oz"
    ]
  },
  {
    "Name": "Sci-Fi and Fantasy Films",
    "Items": [
      "2001: A Space Odyssey",
      "Avatar",
      "Blade Runner",
      "E.T. the Extra-Terrestrial",
      "The Lord of the Rings",
      "Star Wars",
      "The Wizard of Oz",
      "X-Men",
      "Alice in Wonderland",
      "Men in Black",
      "Harry Potter",
      "The Matrix",
      "Nanny McPhee",
      "Iron Man",
      "Tangled",
      "Interstellar"
    ]
  },
  {
    "Name": "Marvel Films",
    "Items": [
      "Avengers: Endgame",
      "Captain Marvel",
      "Spiderman",
      "Black Panther",
      "Thor: Ragnarok",
      "Guardians of the Galaxy",
      "Ant-Man",
      "Doctor Strange",
      "Captain America: The Winter Soldier",
      "Iron Man 1",
      "Avengers: Infinity War",
      "Avengers: Age of Ultron",
      "Iron Man 2",
      "Ant-Man and the Wasp",
      "The Incredible Hulk",
      "Guardians of the Galaxy Vol. 2"
    ]
  },
  {
    "Name": "Characters",
    "Items": [
      "Betty Boop",
      "Charlie Brown",
      "Hello Kitty",
      "Kermit the Frog",
      "Ronald McDonald",
      "Mickey Mouse",
      "Donald Duck",
      "Homer Simpson",
      "Link",
      "Luke Skywalker",
      "Mario",
      "Sonic the Hedgehog",
      "SpongeBob SquarePants",
      "Scooby-Doo",
      "Pikachu",
      "Captain Kirk"
    ]
  },
  {
    "Name": "Heroes and Villains",
    "Items": [
      "Batman",
      "Captain America",
      "Deadpool",
      "Iron Man",
      "Spider-Man",
      "Superman",
      "Wonder Woman",
      "X-Men",
      "Hulk",
      "Catwoman",
      "Joker",
      "Thor",
      "Black Panther",
      "Ant-Man",
      "Black Widow",
      "Loki"
    ]
  },
  {
    "Name": "The Church of Jesus Christ of Latter-Day Saints",
    "Items": [
      "Book of Mormon",
      "Baptism",
      "Church",
      "Temple",
      "Old Testament",
      "New Testament",
      "Tithing",
      "Commandment",
      "Sacrament",
      "Covenant",
      "Prophet",
      "Sealing",
      "CTR",
      "Hymn",
      "Ministering",
      "Family History"
    ]
  },
  {
    "Name": "Death and Afterlife",
    "Items": [
      "Burial",
      "Cemetery",
      "Cremation",
      "Crypt",
      "Funeral",
      "Mausoleum",
      "Mummy",
      "Afterlife",
      "Heaven",
      "Hell",
      "Reincarnation",
      "Soul",
      "Spirit",
      "Underworld",
      "Angels",
      "Demons"
    ]
  },
  {
    "Name": "Mythological Creatures",
    "Items": [
      "Banshee",
      "Centaur",
      "Cyclops",
      "Demon",
      "Devil",
      "Dragon",
      "Sea serpent",
      "Dwarf",
      "Elf",
      "Fairy",
      "Ghost",
      "Ghoul",
      "Giant",
      "Gnome",
      "Golem",
      "Medusa"
    ]
  },
  {
    "Name": "Mythological Creatures 2",
    "Items": [
      "Loch Ness Monster",
      "Leprechaun",
      "Mermaid",
      "Minotaur",
      "Nymph",
      "Ogre",
      "Pegasus",
      "Phoenix",
      "Troll",
      "Unicorn",
      "Poltergeist",
      "Vampire",
      "Werewolf",
      "Zombie",
      "Bigfoot",
      "Yeti"
    ]
  },
  {
    "Name": "Entertainment and leisure",
    "Items": [
      "Aquarium",
      "Beauty pageant",
      "Birdwatching",
      "Book collecting",
      "Coin collecting",
      "Comic book collecting",
      "Stamp collecting",
      "Sneaker collecting",
      "Trading card",
      "Video game collecting",
      "Karaoke",
      "Speed typing contest",
      "Spelling bee",
      "Robot competition",
      "Quiz",
      "Trivia"
    ]
  },
  {
    "Name": "Recreation",
    "Items": [
      "Road trip",
      "Obstacle course",
      "Parkour",
      "Freerunning",
      "Swimming",
      "Camping",
      "Glamping",
      "Canyoning",
      "Hiking",
      "Backpacking",
      "Rafting",
      "Playground",
      "Jungle gym",
      "Sandpit",
      "Aquarium",
      "Zoo"
    ]
  },
  {
    "Name": "Amusement Parks Rides/Types",
    "Items": [
      "Animal theme park",
      "Family entertainment center",
      "Fair",
      "Marine mammal park",
      "Miniature park",
      "Safari park",
      "Traveling carnival",
      "Water park",
      "Bumper cars",
      "Carousel",
      "Dark ride",
      "Ferris wheel",
      "Roller coaster",
      "Water slide",
      "Drop tower",
      "Kiddie ride"
    ]
  },
  {
    "Name": "Toys",
    "Items": [
      "Action figure",
      "G.I. Joe",
      "Transformers",
      "Doll",
      "Barbie",
      "Hot Wheels",
      "Frisbee",
      "Hula hoop",
      "Kite",
      "Lego",
      "Pogo stick",
      "Rubik's Cube",
      "Slinky",
      "Top",
      "Water gun",
      "Yo-yo"
    ]
  },
  {
    "Name": "Game Types",
    "Items": [
      "Game of chance",
      "Drinking",
      "Lawn",
      "Party",
      "Role-playing",
      "Arcade",
      "Game of skill",
      "Charades",
      "Strategy",
      "Street",
      "Trick-taking",
      "Wargame",
      "Maze",
      "Riddle",
      "Puzzle",
      "Guessing"
    ]
  },
  {
    "Name": "Board Games",
    "Items": [
      "Backgammon",
      "Battleship",
      "Chess",
      "Chinese checkers",
      "Clue",
      "Catan",
      "Ticket to Ride",
      "The Game of Life",
      "Mancala",
      "Mastermind",
      "Monopoly",
      "Risk",
      "Scrabble",
      "Chutes and Ladders",
      "Stratego",
      "Trivial Pursuit"
    ]
  },
  {
    "Name": "Card Games",
    "Items": [
      "Blackjack",
      "Rook",
      "Spades",
      "Hearts",
      "Rummy",
      "Klondike",
      "Poker",
      "I Doubt It",
      "Texas hold 'em",
      "War",
      "Slapjack",
      "Go Fish",
      "Castle",
      "Egyptian Ratscrew",
      "Spoons",
      "Speed"
    ]
  },
  {
    "Name": "Video Game Genres",
    "Items": [
      "Action",
      "First-person shooter",
      "Platform",
      "Adventure",
      "Text adventure",
      "Visual novel",
      "Multiplayer",
      "Online",
      "Puzzle",
      "Role-playing",
      "Simulation",
      "Racing",
      "Vehicle simulation",
      "Single-player",
      "Strategy",
      "Text-based"
    ]
  },
  {
    "Name": "Video Game Consoles",
    "Items": [
      "Atari 2600",
      "Intellivision",
      "NES",
      "Master System",
      "Game Boy",
      "Sega Genesis",
      "SNES",
      "Nintendo 64",
      "PlayStation",
      "Dreamcast",
      "GameCube",
      "Xbox",
      "Nintendo DS",
      "Wii",
      "Nintendo Switch",
      "Wii U"
    ]
  },
  {
    "Name": "Video games",
    "Items": [
      "Portal",
      "The Legend of Zelda",
      "Kingdom Hearts",
      "Halo",
      "Pokémon Go",
      "Guitar Hero",
      "The Oregon Trail",
      "League of Legends",
      "Super Mario 64",
      "Duck Hunt",
      "Mortal Kombat",
      "Tomb Raider",
      "World of Warcraft",
      "Doom",
      "Mario Kart",
      "Final Fantasy"
    ]
  },
  {
    "Name": "Video Games 2",
    "Items": [
      "Half-Life",
      "FIFA",
      "Donkey Kong",
      "Call of Duty",
      "Space Invaders",
      "Dance Dance Revolution",
      "Animal Crossing",
      "Candy Crush",
      "Minesweeper",
      "Minecraft",
      "Mega Man",
      "Wii Sports",
      "Sonic the Hedgehog",
      "Team Fortress 2",
      "Fallout",
      "Angry Birds"
    ]
  },
  {
    "Name": "Video Games 3",
    "Items": [
      "Pac-Man",
      "Need for Speed",
      "Assassin's Creed",
      "Pong",
      "Super Smash Bros.",
      "Galaga",
      "Roblox",
      "Tetris",
      "The Sims",
      "Grand Theft Auto",
      "Skyrim",
      "Fortnite",
      "Super Mario Bros.",
      "Star Wars Battlefront",
      "Frogger",
      "Madden"
    ]
  },
  {
    "Name": "Water Sports",
    "Items": [
      "Canoeing",
      "Underwater diving",
      "Scuba diving",
      "Kayaking",
      "Rowing",
      "Sailing",
      "Surfing",
      "Paddleboarding",
      "Swimming",
      "Wakeboarding",
      "Water Polo",
      "Water Skiing",
      "Windsurfing",
      "Parasailing",
      "Snorkeling",
      "Water Aerobics"
    ]
  },
  {
    "Name": "Winter Sports",
    "Items": [
      "Alpine skiing",
      "Cross-country skiing",
      "Ski Jumping",
      "Sledding",
      "Bobsleigh",
      "Skeleton",
      "Snowboarding",
      "Figure skating",
      "Curling",
      "Snow shoeing",
      "Hockey",
      "Ice fishing",
      "Dog sledding",
      "Ice skating",
      "Snow tubing",
      "Snowmobiling"
    ]
  },
  {
    "Name": "Sports",
    "Items": [
      "Cricket",
      "Mountaineering",
      "Tennis",
      "Volleyball",
      "Bowling",
      "Gymnastics",
      "BMX",
      "Water polo",
      "Acrobatics",
      "Snowboarding",
      "Arm wrestling",
      "Football",
      "Skiing",
      "Bodybuilding",
      "Disc golf",
      "Boxing"
    ]
  },
  {
    "Name": "Sports 2",
    "Items": [
      "Bungee jumping",
      "Baseball",
      "Rock climbing",
      "Surfing",
      "Table tennis",
      "Archery",
      "Soccer",
      "Mountain biking",
      "Cheerleading",
      "Bocce",
      "Lacrosse",
      "Pool",
      "Fencing",
      "Basketball",
      "Powerlifting",
      "Weight training"
    ]
  },
  {
    "Name": "Sports 3",
    "Items": [
      "Darts",
      "Dancing",
      "Racquetball",
      "Tennis",
      "Golf",
      "Triathlon",
      "Skateboarding",
      "Hockey",
      "Bullfighting",
      "Competitive eating",
      "Badminton",
      "Esports",
      "Archery",
      "Croquet",
      "Trampolining",
      "Polo"
    ]
  },
  {
    "Name": "Memes",
    "Items": [
      "Pepe the Frog",
      "Harambe",
      "Grumpy Cat",
      "Rickrolling",
      "Doge",
      "Nyan cat",
      "Frööt Lööp cat",
      "Baby yoda",
      "I can haz cheezburger?",
      "Salesman slapping roof",
      "Yeet",
      "Netflix and chill",
      "Mic drop",
      "Trollface",
      "Hide the Pain Harold",
      "Spider-Man pointing at Spider-Man"
    ]
  },
  {
    "Name": "Business",
    "Items": [
      "Capital",
      "Bankruptcy",
      "Commerce",
      "Entrepreneurship",
      "Logistics",
      "Management",
      "Marketing",
      "Advertising",
      "Brand",
      "Retail",
      "Sales",
      "Partnership",
      "Real estate",
      "Supply chain",
      "Sole proprietorship",
      "Trade"
    ]
  },
  {
    "Name": "Banking and Finance",
    "Items": [
      "Investment",
      "Debit card",
      "ATM",
      "Dividend",
      "Finance",
      "Interest",
      "Investor",
      "Debt",
      "Credit card",
      "Bond",
      "Fixed income",
      "Loan",
      "Financial market",
      "Stock",
      "Stock exchange",
      "Shareholder"
    ]
  },
  {
    "Name": "Industries",
    "Items": [
      "Agriculture",
      "Construction",
      "Power",
      "Fishing",
      "Forestry",
      "Information",
      "Hunting",
      "Manufacturing",
      "Aerospace",
      "Automotive",
      "Chemical",
      "Textile",
      "Mining",
      "Education",
      "Entertainment",
      "Transportation"
    ]
  },
  {
    "Name": "Employment",
    "Items": [
      "Blue-collar worker",
      "Career",
      "Unionism",
      "Strike",
      "Layoff",
      "Mentorship",
      "Occupational injury",
      "Job interview",
      "Outsourcing",
      "Résumé",
      "Retirement",
      "Sweatshop",
      "Employee benefits",
      "Minimum wage",
      "Salary",
      "White-collar worker"
    ]
  },
  {
    "Name": "Jobs",
    "Items": [
      "Accountant",
      "Bank teller",
      "Bartender",
      "Cashier",
      "Cook",
      "Electrician",
      "Engineer",
      "Hairdresser",
      "Janitor",
      "Mechanic",
      "Plumber",
      "Police officer",
      "Politician",
      "Programmer",
      "Teacher",
      "Waiter"
    ]
  },
  {
    "Name": "Companies",
    "Items": [
      "3M",
      "AT&T",
      "Burger King",
      "Caterpillar Inc.",
      "The Coca-Cola Company",
      "Colgate-Palmolive",
      "Comcast",
      "General Electric",
      "IKEA",
      "Johnson & Johnson",
      "McDonald's",
      "Nestlé",
      "Panasonic",
      "Procter & Gamble",
      "Sony",
      "Verizon Communications"
    ]
  },
  {
    "Name": "Car Brands",
    "Items": [
      "BMW",
      "Chrysler",
      "Mercedes-Benz",
      "Fiat",
      "Ford",
      "GM",
      "Chevrolet",
      "Honda",
      "Mazda",
      "Nissan",
      "Toyota",
      "Volkswagen",
      "Nissan",
      "Kia",
      "Dodge",
      "Audi"
    ]
  },
  {
    "Name": "Technology Companies",
    "Items": [
      "Oracle",
      "Hitachi",
      "Epson",
      "Broadcom",
      "Nokia",
      "Panasonic",
      "Samsung",
      "Toshiba",
      "Dell",
      "Norton",
      "Google",
      "Adobe",
      "Fujitsu",
      "Apple",
      "Huawei",
      "AMD"
    ]
  },
  {
    "Name": "Technology Companies 2",
    "Items": [
      "IBM",
      "HP",
      "Microsoft",
      "Facebook",
      "Asus",
      "Acer",
      "Western Digital",
      "Intel",
      "Lenovo",
      "PayPal",
      "Nvidia",
      "LG",
      "Seagate",
      "Intuit",
      "Cisco",
      "Qualcomm"
    ]
  },
  {
    "Name": "Media Companies",
    "Items": [
      "Blizzard Entertainment",
      "Ubisoft",
      "DC Comics",
      "Marvel",
      "Metro-Goldwyn-Mayer",
      "20th Century Studios",
      "Universal",
      "Nintendo",
      "Disney",
      "Pixar",
      "Lionsgate",
      "Netflix",
      "Sony",
      "Warner Bros.",
      "NBC",
      "Paramount"
    ]
  },
  {
    "Name": "Retailers",
    "Items": [
      "Aldi",
      "Amazon",
      "Costco",
      "The Home Depot",
      "Kohl's",
      "Sears",
      "Target",
      "Walgreens",
      "Walmart",
      "Best Buy",
      "Albertson's",
      "Whole Foods",
      "Macy's",
      "Dollar Tree",
      "Saver's",
      "Old Navy"
    ]
  },
  {
    "Name": "Transport Companies",
    "Items": [
      "Air Canada",
      "American Airlines",
      "Delta Airlines",
      "FedEx Express",
      "Southwest Airlines",
      "United Airlines",
      "Air France",
      "British Airways",
      "Lufthansa",
      "Alaska Airlines",
      "Allegiant Airlines",
      "Frontier Airlines",
      "UPS",
      "Hawaiian Airlines",
      "JetBlue Airlines",
      "Spirit Airlines"
    ]
  },
  {
    "Name": "Communication",
    "Items": [
      "Video call",
      "Conference call",
      "Conversation",
      "Interview",
      "Debate",
      "Meeting",
      "Convention",
      "Parent-teacher conference",
      "Assembly",
      "Town hall meeting",
      "Board meeting",
      "Public speech",
      "Phone call",
      "Radio broadcast",
      "TV broadcast",
      "Text message"
    ]
  },
  {
    "Name": "Holidays",
    "Items": [
      "April Fools' Day",
      "Memorial Day",
      "Father's Day",
      "Thanksgiving",
      "Independence Day",
      "Mother's Day",
      "Halloween",
      "Veterans Day",
      "Labour Day",
      "New Year's Day",
      "Martin Luther King Jr. Day",
      "Saint Patrick's Day",
      "Valentine's Day",
      "Christmas",
      "Easter",
      "Hanukkah"
    ]
  },
  {
    "Name": "Places of Education",
    "Items": [
      "Academy",
      "Business school",
      "College",
      "Community college",
      "High school",
      "Junior high school",
      "Law school",
      "Preschool",
      "Kindergarten",
      "Boarding school",
      "Military academy",
      "Catholic school",
      "Elementary school",
      "Public school",
      "Private school",
      "University"
    ]
  },
  {
    "Name": "Academic Fields",
    "Items": [
      "Culinary Arts",
      "Literature",
      "Area Studies",
      "Psychology",
      "Pharmacology",
      "Theology",
      "Marketing",
      "Sports Medicine",
      "English",
      "Genetics",
      "Criminal Law",
      "Public Health",
      "Aerospace Engineering",
      "Visual Arts",
      "History",
      "Civil Engineering"
    ]
  },
  {
    "Name": "Academic Fields 2",
    "Items": [
      "Photography",
      "Astronomy",
      "Statistics",
      "Public Safety",
      "Interior Design",
      "Optometry",
      "Surgery",
      "Physical Fitness",
      "Music",
      "Anatomy",
      "Finance",
      "Dermatology",
      "Chemical Engineering",
      "Chemistry",
      "Calculus",
      "Linguistics"
    ]
  },
  {
    "Name": "Academic Fields 3",
    "Items": [
      "Philosophy",
      "Geography",
      "Architecture",
      "Economics",
      "Algebra",
      "Mechanical Engineering",
      "Neuroscience",
      "Ethnic Studies",
      "Geometry",
      "Neurology",
      "Military Strategy",
      "Civil Law",
      "Transportation",
      "Social Work",
      "Dentistry",
      "Computer Science"
    ]
  },
  {
    "Name": "Academic Fields 4",
    "Items": [
      "Journalism",
      "Meteorology",
      "Film",
      "Archaeology",
      "Botany",
      "Environmental Science",
      "Biochemistry",
      "Electrical Engineering",
      "Network Engineering",
      "Physiology",
      "Graphic Designer",
      "Nutrition",
      "Biology",
      "Geology",
      "Anthropology",
      "Dance"
    ]
  },
  {
    "Name": "Academic Fields 5",
    "Items": [
      "Education",
      "Physics",
      "Accounting",
      "Medicine",
      "Sociology",
      "Agriculture",
      "Nursing",
      "Cybersecurity",
      "Urban Planning",
      "Mechanics",
      "Political Science",
      "Zoology",
      "Forensic Pathology",
      "Business",
      "Information Science",
      "Communcations"
    ]
  },
  {
    "Name": "U.S. Universities",
    "Items": [
      "Purdue University",
      "Brigham Young University",
      "Johns Hopkins University",
      "Kansas City University",
      "Princeton University",
      "U.S. Air Force Academy",
      "U.S. Military Academy",
      "U.S. Naval Academy",
      "Harvard University",
      "Stanford University",
      "University of Mississippi",
      "Cornell University",
      "Yale University",
      "Utah State University",
      "Utah Valley University",
      "University of Utah"
    ]
  },
  {
    "Name": "Language",
    "Items": [
      "First language",
      "Fluency",
      "Language preservation",
      "Literacy",
      "Reading",
      "Natural language",
      "Pronunciation",
      "Second language",
      "Speech",
      "Translation",
      "Language interpretation",
      "Visual language",
      "World language",
      "Alphabet",
      "Letter",
      "Writing system"
    ]
  },
  {
    "Name": "Punctuation",
    "Items": [
      "Apostrophe",
      "Braces",
      "Colon",
      "Comma",
      "Dash",
      "Ellipsis",
      "Exclamation mark",
      "Square brackets",
      "Hyphen",
      "Question mark",
      "Quotation mark",
      "Semicolon",
      "Slash",
      "At sign",
      "Asterisk",
      "Parentheses"
    ]
  },
  {
    "Name": "Languages",
    "Items": [
      "English",
      "Arabic",
      "Chinese",
      "Danish",
      "French",
      "German",
      "Greek",
      "Hebrew",
      "Italian",
      "Japanese",
      "Korean",
      "Latin",
      "Portuguese",
      "Russian",
      "Spanish",
      "Vietnamese"
    ]
  },
];

export default categories;