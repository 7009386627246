import React, {useState, useEffect} from 'react';
import { Button, FormControlLabel, Switch, Typography } from '@mui/material';
import { LocalOfferRounded, LoopRounded, CategoryRounded, TuneRounded, CheckRounded } from '@mui/icons-material';
import { mdiNutrition } from '@mdi/js';
import Icon from '@mdi/react';

import FilterMenu from "./filterMenu"
import BackToHome from 'components/BackToHome';
import icon from './favicon.png';
import styles from "./style.module.scss";

export default function CFHeader ({changeFilters, resetFilters, filters}) {
  const {category, tag, allergen, imageOnly, hidePrices} = filters;
  const [filtersOpen, setFiltersOpen] = useState(window.innerWidth > 550);
  useEffect(() => {
    window.addEventListener("resize", () => {
      setFiltersOpen(window.innerWidth > 550);
    });
  });

  return (<>
    <div className={styles.header}>
      <BackToHome className={styles.backToHome}/>
      <img src={icon}/>
      <h1>The Cheesecake Factory Decider</h1>
    </div>
    <div className={styles.controls} style={{display: filtersOpen ? "block" : "none"}}>
      <FilterMenu
        filters={category}
        icon={<CategoryRounded/>}
        labelSingular="category"
        labelPlural="categories"
        changeFilters={(newFilters) => changeFilters({category: newFilters})}
      />
      <FilterMenu
        filters={tag}
        icon={<LocalOfferRounded/>}
        labelSingular="tag"
        labelPlural="tags"
        changeFilters={(newFilters) => changeFilters({tag: newFilters})}
      />
      <FilterMenu
        filters={allergen}
        icon={<Icon path={mdiNutrition} size={1}/>}
        labelSingular="allergen"
        labelPlural="allergens"
        changeFilters={(newFilters) => changeFilters({allergen: newFilters})}
      />
      <FormControlLabel
        control={
          <Switch 
            checked={imageOnly} 
            onChange={() => changeFilters({imageOnly: !imageOnly})}
          />}
        label={<Typography variant="button">Hide Imageless</Typography>}
      />
      <FormControlLabel
        control={
          <Switch 
            checked={hidePrices} 
            onChange={() => changeFilters({hidePrices: !hidePrices})}
          />}
        label={<Typography variant="button">Hide Prices</Typography>}
      />
      <Button 
        startIcon={<LoopRounded/>} 
        onClick={resetFilters}
        disabled={
          category.filter(f => f.checked).length === category.length &&
          tag.filter(f => f.checked).length === tag.length &&
          allergen.filter(f => f.checked).length === allergen.length &&
          !imageOnly && !hidePrices
        }
      >
        Reset Filters
      </Button>
    </div>
    {filtersOpen ? <div className={styles.collapseControls} onClick={() => setFiltersOpen(false)}>
      <CheckRounded/>
      <Typography variant="button">
        Save & Close Filters
      </Typography>
    </div> : <div className={styles.expandControls} onClick={() => setFiltersOpen(true)}>
      <TuneRounded/>
      <Typography variant="button">
        Expand Filters
      </Typography>
    </div>}
    
  </>);
}