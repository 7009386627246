import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Snackbar } from '@mui/material';
import MuiAlert from '@mui/lab/Alert';

import SettingsForm from './Form';
import styles from './style.module.scss';

export default class App extends Component {
  constructor(props) {
    super(props);
    const settings = JSON.parse(window.localStorage.getItem("settings"));
    this.state = {
      notification: {
        open: false,
      },
      initialFormValues: { 
        items: settings?.itemCount || 10, 
        time: settings?.timeLimit || 20,
        options: settings?.customItems !== "" || settings?.customItemsOnly === true,
        customItems: settings?.customItems || "",
        customItemsOnly: settings?.customItemsOnly || false,
      }
    };
  }
  
  showNotification(props) {
    const {message, type} = props;
    this.setState({
      notification: {
        message,
        type,
        open: true,
        onClose: () => {
          this.setState({notification:{open: false}});
        },
      }
    });
  };

  onFinish = values => {
    const { items, time, customItems, customItemsOnly } = values;

    if (customItemsOnly) {
      if (!customItems || (customItems && customItems.replace(/[ \r\n]/g, "") === "")) {
        return this.showNotification({
          message: "If you want to only play with custom items, you must type some in!",
          type: 'error'
        });
      }
    }

    window.localStorage.setItem("settings", JSON.stringify({
      itemCount: items,
      timeLimit: time,
      customItems,
      customItemsOnly: customItemsOnly || false,
    }));
    this.setState({
      redirect: "play",
    });
  }

  render() {
    const { notification, initialFormValues, redirect } = this.state;

    return (
      <div>
        {Boolean(redirect) ? <Redirect to={`/projects/street-view-bingo/${redirect}`}/> : null}
        <div>
          <div className={styles.App}>
            <div className={styles.AppHeader}>
              <Snackbar
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={notification.open}
                autoHideDuration={6000}
                onClose={notification.onClose}
              >
                <MuiAlert elevation={6} variant="filled" onClose={notification.onClose} severity={notification.type}>
                  {notification.message}
                </MuiAlert>
              </Snackbar>
              <h1 className={styles.presents}><Link to="/" className={styles.name}>Jo the Dev</Link> presents:</h1>
              <h1><span className={styles.yellow}>StreetView</span> Bingo</h1>
              <h2 className={styles.settingsTitle}>Settings</h2>
              <SettingsForm
                initialValues={initialFormValues}
                onSubmit={this.onFinish}
              />
              <span className={styles.inspired}>Inspired by <a href="https://www.youtube.com/playlist?list=PL_japiE6QKWoGGZw1t2cebElAki6JU1ik" target="_blank" rel="noopener noreferrer">Tom Davies' Street View Bingo</a> series</span>
              <br />
              <span className={styles.inspired2}><Link to="/projects/street-view-bingo/credits">Credits</Link> | <Link to="/contact?message=List your StreetView Bingo suggestions here:%0D%0A%0D%0ACan I list your name in the game's credits (Y/N)?">Suggest items!</Link></span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}