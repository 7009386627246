import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel,
  Toolbar, Typography, Paper, Checkbox, IconButton, Tooltip, Select, MenuItem, TextField} from '@mui/material';
import {Delete, FilterList, AddBox} from '@mui/icons-material';
import {makeStyles, styled} from '@mui/material/styles';
import {withStyles} from '@mui/styles';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: 'name', numeric: false, disablePadding: true, label: 'Dessert (100g serving)' },
  { id: 'calories', numeric: true, disablePadding: false, label: 'Calories' },
  { id: 'fat', numeric: true, disablePadding: false, label: 'Fat (g)' },
  { id: 'carbs', numeric: true, disablePadding: false, label: 'Carbs (g)' },
  { id: 'protein', numeric: true, disablePadding: false, label: 'Protein (g)' },
];

function EnhancedTableHead(props) {
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort,
    schema  
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all desserts' }}
          />
        </TableCell>
        {schema.map((schemaValue) => (
          <TableCell
            key={schemaValue.id}
            align={schemaValue.type == "number" ? 'right' : 'left'}
            padding={'default'}//headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === schemaValue.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === schemaValue.id}
              direction={orderBy === schemaValue.id ? order : 'asc'}
              onClick={createSortHandler(schemaValue.id)}
            >
              {schemaValue.label}
              {orderBy === schemaValue.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight: {
    color: "#fff",
    backgroundColor: "#D62525",
  },
  title: {
    flex: '1 1 100%',
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected, title } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
          {numSelected} selected
        </Typography>
      ) : (
        <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
          {title}
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton aria-label="delete">
            <Delete/>
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton aria-label="filter list">
            <FilterList/>
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = (theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
});

class DataTable extends React.Component {
  constructor(props) {
    super(props);

    let data = [
      {
        name: "Juan Carlos",
        age: 420,
        gender: "Male",
        handedness: "Right",
        politicalAffilication: "Democrat",
        deadInside: true
      },
      {
        name: "Maria Reay",
        age: 69,
        gender: "Female",
        handedness: "Left",
        politicalAffilication: "Republican",
        deadInside: false
      },
      {
        name: "John Smith",
        age: 666,
        gender: "Male",
        handedness: "Ambidextrous",
        politicalAffilication: "Conservative",
        deadInside: false
      },
      {
        name: "Benjamin Franklin",
        age: 89,
        gender: "Male",
        handedness: "Right",
        politicalAffilication: "Liberal",
        deadInside: true
      },
      {
        name: "Patty Saint Cake",
        age: 32,
        gender: "Female",
        handedness: "Left",
        politicalAffilication: "Unaffiliated",
        deadInside: false
      },
      {
        name: "Ben Wyatt",
        age: 46,
        gender: "Male",
        handedness: "Right",
        politicalAffilication: "Pawnee Council",
        deadInside: false
      },
    ];
    data = data.map((item, index) => (Object.assign({id: index}, item)));

    this.state = {
      data,
      schema: [
        {
          id: 'name',
          label: 'Name',
          type: 'freetext',
        },
        {
          id: 'age',
          label: 'Age',
          type: 'number',
        },
        {
          id: 'gender',
          label: 'Sex',
          type: 'choice',
          choices: [ "Female", "Male"],
        },
        {
          id: 'handedness',
          label: 'Handedness',
          type: 'choice',
          choices: ["Left", "Right", "Ambidextrous"],
        },
        {
          id: 'politicalAffilication',
          label: 'Political Affiliation',
          type: 'freetext',
        },
        {
          id: 'deadInside',
          label: 'Dead Inside?',
          type: 'boolean'
        }
      ],
      order: "asc",
      orderBy: "name", 
      selected: [],
      //page: 0,
      //rowsPerPage: 5,
    };
  }

  handleRequestSort = (event, property) => {
    const isAsc = this.state.orderBy === property && this.state.order === 'asc';
    this.setState({
      order: isAsc ? 'desc' : 'asc',
      orderBy: property,
    });
  };

  handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelectedRows = this.state.data.map((n) => n.name);
      return this.setState({
        selected: newSelectedRows,
      });
    }
    this.setState({
      selected: [],
    });
  };

  handleClick = (event, id) => {
    const {selected} = this.state;

    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    this.setState({
      selected: newSelected,
    });
  };

  /*handleChangePage = (event, newPage) => {
    this.setState({
      page: newPage,
    });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
    });
  };*/

  isSelected = (name) => {
    return this.state.selected.indexOf(name) !== -1;
  };

  render() {
    const {
      //data,
      title,
      classes,
      //schema,
    } = this.props;
    const {data, schema, selected, order, orderBy, isCustomOrder} = this.state;

    //const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);
    const StyledSelect = styled(Select)({
      '& ': {
        width: "100%",
      },
    });

    return (
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <EnhancedTableToolbar 
            numSelected={selected.length}
            title={title}
          />
          <TableContainer>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size='medium'
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                classes={classes}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                isCustomOrder={isCustomOrder}
                onSelectAllClick={this.handleSelectAllClick}
                onRequestSort={this.handleRequestSort}
                rowCount={data.length}
                schema={schema}
              />
              <TableBody>
                {stableSort(data, getComparator(order, orderBy))
                  //.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const rowId = row.id;
                    const isItemSelected = this.isSelected(rowId);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={rowId}
                        selected={isItemSelected}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            onClick={(event) => this.handleClick(event, rowId)}
                            checked={isItemSelected}
                            inputProps={{ 'aria-labelledby': labelId }}
                          />
                        </TableCell>
                        {schema.map((schemaItem) => {
                          //<TableCell component="th" id={labelId} scope="row" padding="none">
                           // {row[schemaItem.id]}
                          //</TableCell>
                          const rowEdited = (newValue) => {
                            const {data, order, orderBy, orderChanged} = this.state;
                            //console.log("prev: ", data);
                            const sortedData = stableSort(data, getComparator(order, orderBy)).map((row, index) => (Object.assign({"%custom%": index}, row)));
                            sortedData.find(item => item.id === rowId)[schemaItem.id] = newValue;
                            //console.log(sortedData);
                            this.setState({
                              data: sortedData,
                              orderBy: "%custom%",
                              order: "asc",
                            });
                            if (!orderChanged) {
                              this.setState({
                                prevOrderBy: orderBy,
                                prevOrder: order,
                                orderChanged: true,
                              });
                            }
                          };
                          const rowFocusGained = () => {
                            this.setState({
                              orderChanged: false,
                            });
                          };
                          const rowFocusLost = () => {
                            const {orderChanged, prevOrderBy, prevOrder} = this.state;
                            if (!orderChanged)
                              return;

                            this.setState({
                              orderBy: prevOrderBy,
                              order: prevOrder,
                            });
                          };
                          if (schemaItem.type === "boolean") {
                            return (
                              <TableCell padding="checkbox">
                                <Checkbox
                                  checked={row[schemaItem.id]}
                                  onClick={() => rowEdited(!data[data.indexOf(row)][schemaItem.id])} //invert current boolean value
                                />
                              </TableCell>
                            );
                          }
                          else if (schemaItem.type === "choice") {
                            return (
                              <TableCell padding="checkbox">
                                <StyledSelect
                                  value={row[schemaItem.id]}
                                  onChange={(event) => rowEdited(event.target.value)}
                                >
                                  {schemaItem.choices.map((item) => (
                                    <MenuItem value={item}>{item}</MenuItem>
                                  ))}      
                                </StyledSelect>
                              </TableCell>
                            );
                          }
                          else if (schemaItem.type === "freetext") {
                            return (
                              <TableCell padding="checkbox">
                                <TextField
                                  key={`${rowId}-${schemaItem.id}`}
                                  value={row[schemaItem.id]}
                                  onChange={(event) => rowEdited(event.target.value)}
                                  onFocus={rowFocusGained}
                                  onBlur={rowFocusLost}
                                />
                              </TableCell>
                            );
                          }
                          return (
                            <TableCell align={schemaItem.type == "number" ? 'right' : 'left'}>{row[schemaItem.id]}</TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
                {/*<TableRow hover>
                  <TableCell>
                    <IconButton>
                      <AddBox/>
                    </IconButton>
                  </TableCell>
                  <TableCell>
                    Add New...
                  </TableCell>
                  {Array(Object.entries(schema).length - 1).fill(<TableCell/>)}
                </TableRow>*/}
              </TableBody>
            </Table>
          </TableContainer>
          {/*<TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />*/}
        </Paper>
      </div>
    );
  }
}

export default withStyles(useStyles)(DataTable);