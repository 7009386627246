import React from 'react';
import { Button, IconButton, Dialog, DialogContent, DialogActions, Switch, FormControlLabel, TextField } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { ReplayRounded, SettingsRounded } from '@mui/icons-material';

import lumonLogo from './lumon_logo.svg';
import styles from './style.module.scss';
import { randInt } from 'pages/halloween2021/helpers';
import { randFloat } from 'utils';

function Bin({number, percent}) {
  return (<div className={styles.bin}>
    <div className={styles.numberTitle}>0{number}</div>
    <div className={styles.percent}>{percent}%</div>
  </div>);
}

function DataItem({num}) {
  const [mouseOver, setMouseOver] = React.useState(false);
  return (
    <div 
      className={`${styles.dataItem} ${randInt(0, 2) === 0 ? styles.animUD : styles.animLR} ${mouseOver ? styles.dataMouseOver : ""}`}
      style={{animationDelay: `${randFloat(0, 2000)}ms`, animationDirection: `${randInt(0, 2) === 0 ? "alternate" : "alternate-reverse"}`}}
      onMouseEnter={() => setMouseOver(true)}
      onMouseLeave={() => setMouseOver(false)}
    >
      {num}
    </div>
  );
}

const width = 30,
  height = 10,
  dataRows = new Array(height).fill(null).map((_, i) => new Array(width).fill(null).map((_, j) => 
    <DataItem 
      key={j + i * width} 
      num={randInt(0, 9)} 
    />
  ));

export default function LumonMDRConsole({}) {
  return (<div className={styles.main}>
    <div className={styles.data}>
      {dataRows.map(row => 
        <div className={styles.dataRow}>
          {row}
        </div>)}
    </div>
    <div className={styles.container}>
      <div className={styles.headerWrapper}>
        <div className={styles.header}>
          <h1 className={styles.filename}>Siena</h1>
          <span className={styles.separator}/>
          <h1 className={styles.completion}>0% Complete</h1>
          <img src={lumonLogo} alt="LUMON logo" className={styles.logo}/>
          <div className={styles.logoSpacer}/>
        </div>
        <div className={styles.rightHeaderSpacer}/>
      </div>
      <div className={styles.playFieldView}>
      </div>
      <div className={styles.footerWrapper}>
        <div className={styles.footerContainer}>
          {new Array(5).fill(null).map((_, i) => <Bin key={i} number={i + 1} percent={0}/>)}
        </div>
        <div className={styles.lowerFooter}>
          0x137D58&nbsp;<span>:</span>&nbsp;0x09BCB1
        </div>
      </div>
    </div>
  </div>
  );
}

//#4502 Adam Scout