import React from 'react';

import BackToHome from 'components/BackToHome';
import categories from './categories';
import {rand, shuffleArray} from 'utils';
import styles from './style.module.scss';

export default class ChameleonExpansion extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      boards: categories.slice(),
    };

    this.pickBoard = this.pickBoard.bind(this);

    /*categories.forEach(cat => {
      if (cat.Items.length !== 16)
        console.log(cat.Name);
    });*/
  }

  componentDidMount() {
    this.pickBoard();
  }

  pickBoard() {
    let {boards} = this.state;
    if (boards.length === 0) {
      boards = categories.slice();
    }
    const index = rand(0, boards.length - 1);
    const board = shuffleArray(boards[index]); 
    boards.splice(index, 1);

    this.setState({
      boards,
      board,
    });
  }

  render() {
    const {board} = this.state;
    return (
      <div class={styles.container}>
        <div class={styles.header}>
          <BackToHome topLeft/>
          <h1>{board?.Name}</h1>
          <button onClick={this.pickBoard}>New Board</button>
        </div>
        <div class={styles.board}>
          {(() => {
            let tbl = [
              <div class={styles.coords} style={{gridColumn: 2}}>
                A
              </div>,
              <div class={styles.coords} style={{gridColumn: 3}}>
                B
              </div>,
              <div class={styles.coords} style={{gridColumn: 4}}>
                C
              </div>,
              <div class={styles.coords} style={{gridColumn: 5}}> 
                D
              </div>];
            for (let r = 0; r < 4; r++) {
              let row = [<div class={styles.coordsSide} style={{gridRow: r + 2, gridColumn: 1}}>{r + 1}</div>];
              for (let c = 0; c < 4; c++) {
                const x = r * 4 + c;
                row.push(<div class={`${Math.floor((x+Math.floor(x/4)) % 2) === 0 ? styles.odd : ""} ${styles.td}`} style={{gridRow: r + 2, gridColumn: c + 2}}>{board?.Items[x]}</div>);
              }
              tbl.push(row);
            }
            return tbl;
          })()}
        </div>
      </div>
    );
  }
}