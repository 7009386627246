import React from 'react';
import ReactDOM from 'react-dom';
import CloneDeep from 'clone-deep';
import * as d3 from 'd3';

import style from './style.module.scss';
import img1 from './images/img1.png';
import img2 from './images/img2.png';
import img3 from './images/img3.png';
import img4 from './images/img4.png';
import img5 from './images/img5.png';


const heldTileSpacing = 10;
export class DraggableRect extends React.Component {
    constructor(props) {
        super(props);

        this.convertLog = this.convertLog.bind(this);
    }

    componentDidMount() {
        let handleDrag = d3.drag()
            .subject(function () {
                const me = d3.select(this);
                return { x: me.attr('x'), y: me.attr('y') }
            })
            .on('start', function () { //pickup
                const me = d3.select(this);
                //reset rotation
                me.transition()
                    .duration(250)
                    .attr("transform", "rotate(0)");

                //reset other tiles' rotations
                var plateIndex = me.attr('plateindex');
                var thisTileIndex = me.attr('tileindex');
                var thisTileType = me.attr('fill');
                var heldTileCount = 0;
                for (let i = 0; i < plates[plateIndex].tiles.length; i++) {
                    if (i !== thisTileIndex) { //skip over the one we're holding
                        var tile = plates[plateIndex].tiles[i];
                        if (tile.type === thisTileType) //this tile matches the one held
                        {
                            var otherTile = d3.select(`#${tile.id}`);

                            //set distance left to get to right place
                            var x = otherTile.attr('x');
                            var y = otherTile.attr('y');
                            var targetX = d3.event.x + heldTileSpacing * heldTileCount + tileSize * heldTileCount;
                            var targetY = d3.event.y;
                            otherTile.attr('distleftx', targetX - x);
                            otherTile.attr('distlefty', targetY - y);

                            heldTileCount++;
                            otherTile.transition()
                                .duration(250)
                                .attr("transform", "rotate(0)")
                            otherTile.attr('x', targetX).attr('y', targetY);
                        }
                        else { //non-matching tile on plate, move it to middle
                            var found = false;
                            for (let i = 0; i < middle.length; i++) {
                                if (middle[i].plateType === tile.type) {
                                    found = true;
                                    middle[i].tiles.push(tile);
                                    break;
                                }
                            }
                            if (!found) {
                                middle.push({
                                    plateType: tile.type,
                                    tiles: [tile],
                                })
                            }
                            middleLastAdded.push(tile);
                        }
                    }
                }

                refreshMiddle();
            })
            .on('drag', function () {
                const me = d3.select(this);
                me.attr('x', d3.event.x);
                me.attr('y', d3.event.y);
                //set other tiles' locations
                var plateIndex = me.attr('plateindex');
                var thisTileIndex = me.attr('tileindex');
                var thisTileType = me.attr('fill');
                var heldTileCount = 0;
                for (let i = 0; i < plates[plateIndex].tiles.length; i++) {
                    if (i !== thisTileIndex) { //skip over the one we're holding
                        var tile = plates[plateIndex].tiles[i];
                        if (tile.type === thisTileType) //this tile matches the one held, so move it with this one
                        {
                            heldTileCount++;
                            var otherTile = d3.select(`#${tile.id}`);

                            var distLeftX = otherTile.attr('distleftx');
                            var distLeftY = otherTile.attr('distlefty');
                            if (distLeftX > 1 || distLeftY > 1) {
                                var targetX = d3.event.x + heldTileSpacing * heldTileCount + tileSize * heldTileCount;
                                distLeftX /= 2;
                                var targetY = d3.event.y;
                                distLeftY /= 2;

                                otherTile.attr('distleftx', distLeftX);
                                otherTile.attr('distlefty', distLeftY);

                                otherTile.transition()
                                    .duration(20)
                                    .attr("transform", `rotate(0)`)
                                    .attr('x', targetX - distLeftX)
                                    .attr('y', targetY - distLeftY);
                            }
                            else {
                                otherTile.transition()
                                    .duration(20)
                                    .attr("transform", "rotate(0)")
                                    .attr('x', d3.event.x + heldTileSpacing * heldTileCount + tileSize * heldTileCount)
                                    .attr('y', d3.event.y);
                            }
                        }
                    }
                }
            })
            .on('end', function () {
                const me = d3.select(this);

                if (me.attr("validdrop") === "false") { //didn't drop it in the right place
                    //set this tile's location to its original
                    me.transition()
                        .duration(1000)
                        .attr("transform", me.attr('originaltransform'))
                        .attr("transform-origin", me.attr('originaltransformorigin'))
                        .attr("x", me.attr('originalx'))
                        .attr("y", me.attr('originaly'))

                    //set other tiles' locations
                    var plateIndex = me.attr('plateindex');
                    var thisTileIndex = me.attr('tileindex');
                    var thisTileType = me.attr('fill');
                    for (let i = 0; i < plates[plateIndex].tiles.length; i++) {
                        if (i !== thisTileIndex) { //skip over the one we're holding
                            var tile = plates[plateIndex].tiles[i];
                            if (tile.type === thisTileType) //this tile matches the one held, so move it with this one
                            {
                                var otherTile = d3.select(`#${tile.id}`);

                                otherTile.transition()
                                    .duration(1000)
                                    .attr("transform", otherTile.attr('originaltransform'))
                                    .attr("transform-origin", otherTile.attr('originaltransformorigin'))
                                    .attr("x", otherTile.attr('originalx'))
                                    .attr("y", otherTile.attr('originaly'))
                            }
                        }
                    }

                    //remove just added middle tiles and animate them back to plate
                    for (let lastAdded = 0; lastAdded < middleLastAdded.length; lastAdded++) {
                        for (let i = 0; i < middle.length; i++) {
                            for (let j = 0; j < middle[i].tiles.length; j++) {
                                const tile = middle[i].tiles[j];
                                if (tile === middleLastAdded[lastAdded]) {
                                    let otherTile = d3.select(`#${tile.id}`);
                                    otherTile.transition()
                                        .duration(1000)
                                        .attr("transform", otherTile.attr('originaltransform'))
                                        .attr("transform-origin", otherTile.attr('originaltransformorigin'))
                                        .attr("x", otherTile.attr('originalx'))
                                        .attr("y", otherTile.attr('originaly'))
                                }
                            }
                        }
                    }
                    middle.splice(middle.length - middleLastAdded.length - 1, middleLastAdded.length); //remove added tiles from end of middle list
                    middleLastAdded.splice(0, middleLastAdded.length); //clear list
                    refreshMiddle();
                }
            });

        function refreshMiddle() { //animates middle tiles to be correct positions
            for (let i = 0; i < middle.length; i++) {
                let plateAngle = 360 / middle.length * i;
                var plateX = middlePos.x - Math.cos(plateAngle * (Math.PI / 180)) * (plateDistance / 2);
                var plateY = middlePos.y - Math.sin(plateAngle * (Math.PI / 180)) * (plateDistance / 2);
                var plate = middle[i];
                for (let j = 0; j < plate.tiles.length; j++) {
                    var tileElement = plate.tiles[j];
                    var tile = d3.select(`#${tileElement.id}`);

                    let tileAngle = 360 / plate.tiles.length * j;
                    var tileX = plateX - Math.cos(tileAngle * (Math.PI / 180)) * (tileSize / 4 * plate.tiles.length);
                    var tileY = plateY - Math.sin(tileAngle * (Math.PI / 180)) * (tileSize / 4 * plate.tiles.length);

                    if (tile.attr("rotated") === undefined) {
                        tile.transition()
                            .duration(750)
                            .attr("transform", `rotate(${rand(-15, 15)})`)
                            .attr("transform-origin", `${tileX}px ${tileY}px`);

                        tile.attr("rotated", "true");
                    }

                    tile.transition()
                        .duration(750)
                        .attr("x", tileX)
                        .attr("y", tileY);
                }
            }
        }

        function rand(min, max) {
            return min + Math.random() * (max - min)
        }
        const node = ReactDOM.findDOMNode(this);
        handleDrag(d3.select(node));
    }

    /*animate(element) {
        let start = parseInt(element.attr("rotangle"));
        let posX = parseInt(element.attr("rotposx"));
        let posY = parseInt(element.attr("rotposy"));
        console.log("received angle: " + element.attr("rotangle") + ", posX: " + element.attr("rotposx") + ", posY: " + element.attr("rotposy"));
        for (let i = 0; i < 60; i++) {
            if (start < 0) {
                element.attr("transform", "rotate(" + -1 * Math.pow(1.15, -1 * (i - this.log(1.15, Math.abs(start)))) + ", " + posX + ", " + posY + ")");
            }
            else {
                element.attr("transform", "rotate(" + Math.pow(1.15, -1 * (i - this.log(1.15, Math.abs(start)))) + ", " + posX + ", " + posY + ")");
            }
        }
    }*/

    convertLog(base, num) {
        return Math.log(num) / Math.log(base);
    }

    render() {
        return<rect {...this.props} />
    }
}

var plates = [];
const tileSize = 50;
var middle = [];
const plateDistance = 400;
const middlePos = { x: 500, y: 500 };
var middleLastAdded = [];

export default class Main extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            transitionAppear: true,
            part1: style.container,
            part2: `${style.container} ${style.dark}`,
            plateSize: 88.1,
            plateCount: 9,
            svgElements: [],
            maxPlates: 14,
            defaultPlate: {
                x: 0,
                y: 0,
                visibility: "visible",
                tiles: [
                    {
                        x: 0,
                        y: 0,
                        transform: "",
                        type: "",
                        held: false,
                        visibility: "visible",
                    },
                    {
                        x: 0,
                        y: 0,
                        transform: "",
                        type: "",
                        held: false,
                        visibility: "visible",
                    },
                    {
                        x: 0,
                        y: 0,
                        transform: "",
                        type: "",
                        held: false,
                        visibility: "visible",
                    },
                    {
                        x: 0,
                        y: 0,
                        transform: "",
                        type: "",
                        held: false,
                        visibility: "visible",
                    }
                ],
            },
            allTiles: [],
            svgOnTop: [],
        };

        //fill allTiles
        for (let i = 0; i < 5; i++) {
            for (let j = 0; j < 20; j++) {
                this.state.allTiles.push("url(#img" + (i + 1) + ")");
            }
        }
    

        //setup tiles
        for (let i = 0; i < this.state.plateCount; i++) {
            plates.push(CloneDeep(this.state.defaultPlate));
            //setup random tiles
            for (let j = 0; j < plates[i].tiles.length; j++) {
                var index = this.randWhole(0, this.state.allTiles.length - 1);
                var choice = this.state.allTiles[index];
                plates[i].tiles[j].type = choice;
                this.state.allTiles.splice(index, 1);
            }
        }
        this.refreshSvg();

        this.playClick = this.playClick.bind(this);
        this.minusClick = this.minusClick.bind(this);
        this.plusClick = this.plusClick.bind(this);
    }
    toRadians(angle) {
        return angle * (Math.PI / 180);
    }
    playClick() {
        this.setState({
            part1: `${style.container} ${style.out}`,   
            part2: `${style.container} ${style.dark} ${style.in}`,
        })
    }
    minusClick() { //debug only
        if (this.state.plateCount > 1) {
            let newCount = this.state.plateCount - 1;
            plates.pop();
            this.setState({ plateCount: newCount });
            this.refreshSvg();
        }
    }
    plusClick() { //debug only
        if (this.state.plateCount <= this.state.maxPlates) {
            let newCount = this.state.plateCount + 1;
            plates.push(CloneDeep(this.state.defaultPlate));
            this.setState({ plateCount: newCount });
            this.refreshSvg();
        }
    }
    refreshSvg() {
        let size = 360 / this.state.plateCount * 1.75;

        this.setState({ plateSize: size });

        //set up plate info
        for (let i = 0; i < this.state.plateCount; i++) {
            let angle = 360 / this.state.plateCount * i;

            var cX = 500 - Math.cos(this.toRadians(angle)) * plateDistance;
            var cY = 500 - Math.sin(this.toRadians(angle)) * plateDistance;
            plates[i].x = cX;
            plates[i].y = cY;
            //set tile info for this plate
            for (let j = 0; j < plates[i].tiles.length; j++) {
                var tileAngle = 360 / plates[i].tiles.length * j;
                var tileX = cX - Math.cos(this.toRadians(tileAngle)) * tileSize;
                var tileY = cY - Math.sin(this.toRadians(tileAngle)) * tileSize;
                plates[i].tiles[j] = {
                    x: tileX - (tileSize / 2),
                    y: tileY - (tileSize / 2),
                    transform: {
                        rotate: {
                            angle: this.rand(-45, 45),
                            x: tileX,
                            y: tileY,
                        }
                    },
                    type: plates[i].tiles[j].type,
                    held: plates[i].tiles[j].held,
                    class: plates[i].tiles[j].class,
                    id: "tile-" + this.generateID(),
                };
            }
        }

        //clear plateElements array
        this.setState({
            svgElements: []
        });

        //create plates in svgElements
        for (let i = 0; i < this.state.plateCount; i++) {
            this.state.svgElements.push(<circle fill="#4276B3" cx={plates[i].x} cy={plates[i].y} r={this.state.plateSize} />);
        }

        //create tiles in svgElements
        for (let i = 0; i < this.state.plateCount; i++) {
            for (let j = 0; j < plates[i].tiles.length; j++) {
                this.state.svgElements.push(<DraggableRect
                    className=""
                    transform-origin={`${plates[i].tiles[j].transform.rotate.x}px ${plates[i].tiles[j].transform.rotate.y}px`}
                    transform={`rotate(${plates[i].tiles[j].transform.rotate.angle})`}
                    fill={plates[i].tiles[j].type}
                    x={plates[i].tiles[j].x}
                    y={plates[i].tiles[j].y}
                    width={tileSize}
                    height={tileSize}
                    rx={tileSize / 8}
                    ry={tileSize / 8}
                    visibility={plates[i].tiles[j].visibility}
                    plateindex={i}
                    tileindex={j}
                    id={plates[i].tiles[j].id}
                    distleftx="0"
                    distlefty="0"
                    originalx={plates[i].tiles[j].x}
                    originaly={plates[i].tiles[j].y}
                    originaltransform={`rotate(${plates[i].tiles[j].transform.rotate.angle})`}
                    originaltransformorigin={`${plates[i].tiles[j].transform.rotate.x}px ${plates[i].tiles[j].transform.rotate.y}px`}
                    validdrop="false"
                />);
            }
        }
    }
    generateID() {
        var output = "";
        for (let i = 0; i < 20; i++) {
            var num = this.rand(0, 60); //A-Z, a-z, 0-9

            num += 48; //0-9
            if (num > 57) { //A-Z
                num += 8;
            }
            if (num > 90) { //a-z
                num += 7;
            }

            output += String.fromCharCode(num);
        }
        return output;
    }
    rand(min, max) {
        return min + Math.random() * (max - min);
    }
    randWhole(min, max) {
        return Math.floor(min + Math.random() * (max - min));
    }
    generateMat() {
        //do left blanks
        const padding = 5;
        const bigSpace = 15;
        const matHeight = (tileSize * 5 + padding * 4) + bigSpace + tileSize;
        const matWidth = (tileSize * 10) + (padding * 10) + bigSpace;
        const matOffset = {x: 1000, y: 500 - matHeight / 2};
        var output = [];
        for (let y = 0; y < 5; y++) {
            for (let x = 0; x < y + 1; x++) {
                output.push(<rect 
                    x={-1 * (tileSize + padding) * x + (tileSize * 5) + (padding * 5) + matOffset.x}
                    y={y * tileSize + padding * y + matOffset.y}
                    width={tileSize}
                    height={tileSize}
                    rx={tileSize / 8}
                    ry={tileSize / 8}
                    className={style.matBlankPlaceholder}
                />)
            }
        }

        //do right template
        let fills = [];
        for (let i = 0; i < 5; i++) {
            fills.push("url(#img" + (i + 1) + ")");
        }
        for (let y = 0; y < 5; y++) {
            for (let x = 0; x < 5; x++) {
                const fillIndex = Math.abs(4.0 * (0.25 * (x - y) - Math.floor(0.25 * (x - y))));
                output.push(<rect 
                    x={x * tileSize + x * padding + (tileSize * 6 + padding * 6) + matOffset.x + bigSpace}
                    y={y * tileSize + padding * y + matOffset.y}
                    width={tileSize}
                    height={tileSize}
                    rx={tileSize / 8}
                    ry={tileSize / 8}
                    fill={fills[fillIndex]}
                    className={style.matPlaceholder}
                />)
            }
        }

        //do bottom floor
        for (let x = 1; x <= 10; x++) {
            output.push(<rect 
                x={(matWidth / 10) * x + matOffset.x}
                y={(tileSize * 6 + padding * 6) + matOffset.y}
                width={tileSize}
                height={tileSize}
                rx={tileSize / 8}
                ry={tileSize / 8}
                className={style.matFloor}
            />)
        }

        return output;
    }

    render() {
        return (
            <div className={style.mainContainer}>
                <div className={`${style.container} ${style.dark}`}>
                    <svg className={style.svg} x="0px" y="0px" width="100vw" height="100vh" viewBox="0 0 2000 1000">
                        <defs>
                            <pattern id="img1" x="0" y="0" width="1" height="1" viewBox="0 0 250 250">
                                <image href={img1} width="250" height="250" />
                            </pattern>
                            <pattern id="img2" x="0" y="0" width="1" height="1" viewBox="0 0 250 250">
                                <image href={img2} width="250" height="250" />
                            </pattern>
                            <pattern id="img3" x="0" y="0" width="1" height="1" viewBox="0 0 250 250">
                                <image href={img3} width="250" height="250" />
                            </pattern>
                            <pattern id="img4" x="0" y="0" width="1" height="1" viewBox="0 0 250 250">
                                <image href={img4} width="250" height="250" />
                            </pattern>
                            <pattern id="img5" x="0" y="0" width="1" height="1" viewBox="0 0 250 250">
                                <image href={img5} width="250" height="250" />
                            </pattern>
                        </defs>
                        {this.generateMat()}
                        {this.state.svgElements}
                    </svg>
                </div>
                <div className={this.state.part1}>
                    <div className={style.center}>
                        <h1 className={style.h1}>Blue Tiles</h1>
                        <button className={style.btnPrimary} onClick={this.playClick}>PLAY&raquo;</button>
                    </div>
                </div >
                <div className={this.state.part2}>
                    <div className={style.center}>
                        <h1 className={style.h1}>Blue Tiles</h1>
                        <button className={style.btnPrimary}>PLAY&raquo;</button>
                    </div>
                </div >
            </div>
        );
    }
}