import React from 'react';
import { Button } from "@mui/material";
import { DeleteRounded } from '@mui/icons-material';
import { fabric } from "fabric";
import { easeCubicOut } from 'd3-ease';

import SketchField from "../SketchField";
import Pencil from "../SketchField/Pencil";
import { rand, randInt } from "../helpers";
import { WSConnection } from "../websocket";
import StainedGlass from "../assets/StainedGlass.png";
import styles from "./style.module.scss";

export default class WindowControl extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      width: window.innerWidth - 40,
      height: window.innerHeight,      
    };
    this.dripsToSend = [];
  }

  componentDidMount() {
    this.dripCanvas = new fabric.Canvas(this.dripCanvasRef);
    this.dripCanvas.setWidth(window.innerWidth - 40);
    this.dripCanvas.setHeight(window.innerWidth - 40);    
    window.addEventListener('resize', () => {
      this.setState({
        width: window.innerWidth - 40,
        height: window.innerHeight, 
      });
      this.dripCanvas.setWidth(window.innerWidth - 40);
      this.dripCanvas.setHeight(window.innerWidth - 40);
    });

  }

  startSendingUpdates = () => {
    this.stopSending = false;
    if (this.updateInterval) return; //already sending updates!

    this.updateInterval = setInterval(() => {
      if (this.canvas && this.fabricCanvas) {
        WSConnection.sendMessage({
          upperCanvas: document.getElementsByClassName("upper-canvas")[1].toDataURL(),
          lowerCanvas: this.canvas.toDataURL(),
          drips: this.dripsToSend,
          width: this.state.width,
          height: this.state.width,
        });
        this.dripsToSend.splice(0, this.dripsToSend.length);
        this.fabricCanvas.renderAll();
      }
    }, 100);
  }

  stopSendingUpdates = () => {
    this.stopSending = true;
    setTimeout(() => {
      if (this.stopSending) {
        clearInterval(this.updateInterval);
        this.updateInterval = null;
      }
    }, 1000);
  }

  clear = () => {
    this.dripCanvas.clear();
    this.dripsToSend = [];
    this.canvas.clear();
    this.fabricCanvas.clear();
    WSConnection.sendMessage({clear: true});
  }

  render() {
    const {width, height} = this.state;

    return (<>
      <div className={styles.pageH2}>Use your finger to draw in blood on the windows.</div>
      <div className={styles.sketchboard}>
        <img src={StainedGlass} width={width} height={width}/>
        <canvas ref={(c) => this.dripCanvasRef = c}></canvas>
        <SketchField
          width={width}
          height={width}
          tool={Pencil}
          lineColor='red'
          lineWidth={10}
          ref={(c) => this.canvas = c}
          bindCanvas={(c) => this.fabricCanvas = c}
          onMouseDown={this.startSendingUpdates}
          onMouseUp={this.stopSendingUpdates}
          onMouseMove={(e) => {
            if (randInt(1, 3) === 2) {
              const width = rand(0.4, 2.5);
              const length = rand(5, 100);
              const duration = rand(1000, 7500);
              this.dripsToSend.push({
                x: e.pointer.x,
                y: e.pointer.y,
                width,
                length,
                duration,
              });
              var rect = new fabric.Line([e.pointer.x, e.pointer.y, e.pointer.x, e.pointer.y], {
                height: 0,
                left: e.pointer.x,
                top: e.pointer.y,
                stroke: 'red',
                strokeWidth: width,
                strokeLineCap: "round",
                selectable: false
              });
              this.dripCanvas.add(rect);
              rect.animate('height', length, {
                duration,
                onChange: this.dripCanvas.renderAll.bind(this.dripCanvas),
                easing: easeCubicOut(),
              });
            }
          }}
        />
      </div>
      <div className={styles.clearContainer}>
        <Button color="primary" variant="contained" onClick={this.clear}>
          <DeleteRounded/>Clear
        </Button>
      </div>
    </>);
  }
}