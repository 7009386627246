import React from 'react';
import { Slider } from '@mui/material';
import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "@mui/material/styles";

import {WSConnection} from "../websocket";
import styles from "./style.module.scss";

const muiTheme = createTheme({
  overrides: {
    MuiSlider: {
      thumb: {
        color: "white",
        height: 20,
        borderRadius: 0
      },
      track: {
        color: "red",
        height: 10
      },
      rail: {
        color: "red",
        height: 10
      }
    }
  }
});

export default class  extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      
    };
  }
  
  render() {
    return (<>
      <div className={styles.pageH2}>Use the slider below to rotate the spiders.</div>
      <div className={styles.spiderSliderDiv}>
        <ThemeProvider theme={muiTheme}>
          <Slider defaultValue={50} className={styles.spiderSlider} onChange={(event, pos) => WSConnection.sendMessage('"' + pos + '"')} />
        </ThemeProvider>
      </div>
    </>);
  }
}