import React from 'react';
import Cookies from 'universal-cookie';
import {Redirect} from 'react-router-dom';
import Icon from '@mdi/react';
import {mdiAccountCheck, mdiChevronRight} from '@mdi/js';
import { CircularProgress, LinearProgress, Snackbar } from '@mui/material';
import MuiAlert from '@mui/lab/Alert';

import BackToHome from 'components/BackToHome';
import styles from './style.module.scss';

const loadingState = "LOADING";
const cookies = new Cookies();

export default class SDA extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            redirect: false,
            redirectTo: "",
            loggedIn: false,
            checkingUser: true,
            accessToken: cookies.get('accessToken'),
            stage: 0,
            getSongs: loadingState,
            songs: loadingState,
            analysis: {},
        };
    }

    componentDidMount() {
        const code = new URL(window.location.href).searchParams.get("code");
        if (code) {
            fetch("https://api.jothedev.com/prod/spotify-data-analysis/code", {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    code,
                }),
            })
            .then(response => response.json())
            .then(json => {
                if (json && json.accessToken) {
                    cookies.set('accessToken', json.accessToken, {
                        path: '/',
                    });
                    this.setState({
                        redirect: true,
                        redirectTo: "/projects/music-data-analysis-for-spotify",
                        accessToken: json.accessToken,
                    });
                    this.getUser();
                }
            });
        }
        else {
            this.getUser();
        }
    }

    getUser() {
        if (this.state.accessToken) {
            this.api("https://api.spotify.com/v1/me", {
                method: 'GET',
            }, json => {
                if (json && json.error) {
                    this.setState({
                        loggedIn: false,
                        checkingUser: false,
                        accessToken: null,
                    });
                    cookies.remove('accessToken');
                }
                else if (json && json.display_name) {
                    this.setState({
                        loggedIn: true,
                        name: json.display_name,
                        playlists: loadingState,
                        checkingUser: false,
                    });
                    this.loadPlaylists("https://api.spotify.com/v1/me/playlists", []);
                }
            });
        }
        else {
            this.setState({
                checkingUser: false,
            });
        }
    }

    loadPlaylists(url, playlists) {
        this.api(url, {
            options: 'GET'
        }, json => {
            if (json && json.items) {
                playlists = playlists.concat(json.items);
                this.setState({
                    playlists
                });
                if (json.next) {
                    this.loadPlaylists(json.next, playlists);
                }
            }
        });
    }

    playlistClick = (playlist) => {
        this.setState({
            stage: 1,
            getSongs: {
                progress: 0,
                img: playlist.images && playlist.images[0] && playlist.images[0].url,
                text: playlist.tracks.total > 100 ? `Getting tracks 1-100...` : `Getting playlist's tracks...`,
            },
        });
        const {id} = playlist;
        this.loadSongsFromPlaylist(`https://api.spotify.com/v1/playlists/${id}/tracks`, playlist, []);
    }

    loadSongsFromPlaylist = (url, playlist, songs) => {
        this.api(url, {
            options: 'GET'
        }, json => {
            console.log(json);
            if (json && json.items) {
                songs = songs.concat(json.items);
                this.setState({
                    songs
                });
                if (json.next) {
                    const {getSongs} = this.state;
                    this.setState({
                        getSongs: {
                            img: getSongs.img,
                            progress: 0,
                            text: `Getting tracks ${json.offset + json.limit + 1}-${Math.min(json.offset + json.limit * 2, playlist.tracks.total)}...`,
                        },
                    });
                    this.loadSongsFromPlaylist(json.next, playlist, songs);
                }
                else {
                    //filter out local songs & duplicates
                    songs = songs.filter((song, index) => {
                        if (song.track.isLocal)
                            return false;
                        if (songs.indexOf(song) !== index)
                            return false;
                        return true;
                    });
                    this.getAnalysis(songs, 0);
                }
            }
            else if (json && json.error) {
                this.disconnect();
            }
        });
    }

    getAnalysis = (songs, i) => {
        const {accessToken} = this.state;
        const song = songs[i].track;
        if (song) {
            this.setState({
                getSongs: {
                    img: song.album && song.album.images && song.album.images[0] && song.album.images[0].url,
                    progress: ((i + 1) / songs.length) * 100,
                    text: `Analyzing song ${i + 1}/${songs.length}...`,
                    caption: `"${song.name || "Unknown Song"}" by ${song.artists[0].name || "Unknown Artist"}`
                }
            });
            fetch(`https://api.jothedev.com/prod/spotify-data-analysis?id=${song.id}`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    "Content-Type": 'application/json',
                },
                body: JSON.stringify(song),
            })
            .then(response => response.json())
            .then(json => {
                console.log(json);
                songs[i].analysis = json;
                i++;
                if (i < songs.length) {
                    this.getAnalysis(songs, i);
                }
                else {
                    this.setState({
                        songs,
                    });
                    console.log(songs);
                    this.analyzeAll();
                }
            });
        }
    }

    analyzeAll = () => {
        let {songs} = this.state;
        if (songs.length > 0) {
            let analysis = {
                records: {},
            };
    
            //fill default records
            const ignore = ["type", "id", "uri", "track_href", "analysis_url", "key", "mode", "time_signature"];
            const song = songs[0];
            for (const [key] of Object.entries(song.analysis)) {
                if (!ignore.includes(key)) {
                    analysis.records[key] = {
                        high: {
                            value: Number.MIN_VALUE,
                            song: {},
                        },
                        low: {
                            value: Number.MAX_VALUE,
                            song: {},
                        },
                    };
                }
            }

            //filter out zeroed analyses
            songs = songs.filter(song => {
                for (const [key, value] of Object.entries(song.analysis)) {
                    if (!ignore.includes(key) && value !== 0) {
                        return true;
                    }
                }
                return false;
            });
    
            songs.forEach(song => {
                for (const [key, value] of Object.entries(song.analysis)) {
                    if (!ignore.includes(key)) {
                        if (value < analysis.records[key].low.value) {
                            analysis.records[key].low = {
                                value,
                                song,
                            };
                        }
                        if (value > analysis.records[key].high.value) {
                            analysis.records[key].high = {
                                value,
                                song,
                            };
                        }
                    }
                }
            });

            console.log(analysis);
            this.setState({
                analysis,
            });
        }
        

        //done
        this.setState({
            stage: 2,
        });
    }

    renderPlaylists() {
        const {playlists} = this.state;
        let toReturn = [];
        for (let i = 0; i < playlists.length; i++) {
            const playlist = playlists[i];
            toReturn.push(
                <div className={styles.playlist} onClick={() => this.playlistClick(playlist)}>
                    <div className={styles.playlistInnerContent}>
                        <img src={playlist.images && playlist.images[0] && playlist.images[0].url} alt="Playlist cover" className={styles.playlistImage}/>
                        <div>
                            <p className={styles.playlistName}>{playlist.name}</p>
                            <div className={styles.playlistMoreInfo}>
                                <p className={!playlist.public ? styles.playlistPrivate : null}>{playlist.public ? "Public" : "Private"}</p>
                                <p>•</p>
                                <p className={styles.playlistTrackCount}>{playlist.tracks.total} {playlist.tracks.total === 1 ? "track" : "tracks"}</p>
                            </div>
                        </div>
                    </div>
                    <div className={styles.playlistArrow}>
                        <Icon path={mdiChevronRight} size={3}/>
                    </div>
                </div>
            );
        }
        return toReturn;
    }

    api(endpoint, options, callback) {
        const {accessToken} = this.state;
        options.headers = {};
        options.headers.Authorization = `Bearer ${accessToken}`;
        fetch(endpoint, options)
        .then(response => response.json())
        .then(json => {
            return callback(json);
        }, err => {
            this.disconnect();
            this.setState({
                notification: {
                    message: 'Something went wrong trying to connect to Spotify. Try again?',
                    type: 'error',
                    open: true,
                    onClose: () => {
                        this.setState({
                            notification: {
                                open: false,
                            },
                        });
                    },
                },
            });
        });
    }
    
    disconnect = () => {
        cookies.remove("accessToken", {
            path: '/',
        });
        this.setState({
            loggedIn: false,
            accessToken: null,
            playlists: loadingState,
            songs: loadingState,
        });
    }

    renderRecords() {
        const {analysis} = this.state;
        const {records} = analysis;
        let toReturn = [<div className={styles.recordLine}>
            <div className={styles.recordNameHeader} />
            <div className={styles.recordLowHeader}>Lowest</div>
            <div className={styles.recordHighHeader}>Highest</div>
        </div>];
        for (let [key, record] of Object.entries(records)) {
            const noProgress = ["duration_ms", "tempo"];
            const showProgress = !noProgress.includes(key);
            if (key === "duration_ms") {
                key = "Duration";
                record.low.value = this.formatDuration(record.low.value);
                record.high.value = this.formatDuration(record.high.value);
            }
            else if (key === "loudness") {
                record.low.value = -1 * Math.abs(record.low.value) + 100;
                record.high.value = -1 * Math.abs(record.high.value) + 100;
            }
            else if (showProgress) {
                record.low.value = record.low.value * 100;
                record.high.value = record.high.value * 100;
            }
            toReturn.push(
                <div className={styles.recordLine}>
                    <div className={styles.recordName}>{key}</div>
                    <div className={styles.recordLow}><span className={styles.recordValue}></span>{this.renderSong(record.low.song, record.low.value, showProgress)}</div>
                    <div className={styles.recordHigh}><span className={styles.recordValue}></span>{this.renderSong(record.high.song, record.high.value, showProgress)}</div>
                </div>
            );
        }
        return toReturn;
    }

    formatDuration(ms) {
        if (ms < 1000) {
            return `0.${ms}`;
        }
        const min = Math.round(ms / 1000 / 60);
        const sec = Math.round((ms / 1000) % 60);
        return `${(`0${min}`).slice(-2)}:${(`0${sec}`.slice(-2))}`;
    }

    renderSong(song, imgValue, showProgress) {
        if (song && song.track) {
            const {name, artists, album} = song.track;
            if (name && artists && artists.length > 0) {
                let artistsName = "";
                if (artists.length === 1) {
                    artistsName = artists[0].name;
                }
                else if (artists.length === 2) {
                    artistsName = `${artists[0].name} & ${artists[1].name}`;
                }
                else if (artists.length > 2) {
                    for (let i = 0; i < artists.length; i++) {
                        if (i + 1 === artists.length) {
                            artistsName += `and ${artists[i].name}`;
                        }
                        else {
                            artistsName += `${artists[i].name}, `;
                        }
                    }
                }
                return (
                    <div className={styles.songContainer}>
                        {imgValue ? (
                            <div className={styles.songImageContainer}>
                                <img className={styles.songImage} alt={`${album && album.name} cover art`} src={album && album.images && album.images[0] && album.images[0].url}/>
                                {showProgress && <CircularProgress className={styles.loader} variant="static" value={imgValue}/>}
                                <span>{Number.isNaN(Number(imgValue)) ? imgValue : imgValue < 1 ? imgValue.toFixed(2) : Math.round(imgValue)}</span>
                            </div>
                        ) : (
                            <img className={styles.songImage} alt={`${album && album.name} cover art`} src={album && album.images && album.images[0] && album.images[0].url}/>
                        )}
                        <span className={styles.songTitle}>{name}</span>
                        <span className={styles.songArtist}>{artistsName}</span>
                    </div>
                );
            }
        }
    }

    render() {
        const {checkingUser, notification, redirectTo, redirect, loggedIn, name, playlists, stage, getSongs} = this.state;
        return (
            <>
                {redirect ? <Redirect to={redirectTo}/> : null}
                {loggedIn ? (
                    <>
                    {notification && <Snackbar
                        anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                        }}
                        open={notification.open}
                        autoHideDuration={6000}
                        onClose={notification.onClose}
                    >
                        <MuiAlert elevation={6} variant="filled" onClose={notification.onClose} severity={notification.type}>
                            {notification.message}
                        </MuiAlert>
                    </Snackbar>}
                    <div className={styles.gridContainer}>
                        <div className={styles.persist}>
                            <BackToHome align="left"/>
                        </div>
                        <div className="centeredContainerHorz">
                            <div className={styles.connectedAs}>
                                <Icon path={mdiAccountCheck} size={1}/>
                                <h1>Connected as <span className={styles.username}>{name}</span></h1>
                                <span>•</span>
                                <button className={styles.disconnect} onClick={this.disconnect}>Disconnect</button>
                            </div>
                        </div>
                        <div className={`centeredContainerHorz ${styles.container} ${stage > 0 ? styles.divOut : null}`}>
                            {playlists === loadingState ? (
                                <div className={styles.playlistsLoading}>
                                    <CircularProgress className={styles.loader}/>
                                    <p>Loading playlists...</p>
                                </div>
                            ) : (
                                <>
                                    <div className={styles.headerContainer}>
                                        <h1 className={styles.header}><span>{">"}</span> Choose a playlist</h1>
                                    </div>
                                    <div className={styles.playlistsList}>
                                        {this.renderPlaylists()}
                                    </div>
                                </>
                            )}
                        </div>
                        <div className={`centeredContainerHorz ${styles.container} ${stage === 1 ? styles.divIn : stage > 1 ? styles.divOut : styles.divOffscreen}`}>
                            <div className={styles.headerContainer}>
                                <h1 className={styles.header}><span>{">"}</span> Getting and analyzing tracks...</h1>
                            </div>
                            <div className={styles.getSongContainer}>
                                {getSongs !== loadingState ? (
                                    <>
                                        <div className={styles.getSongGrid}>
                                            <img className={styles.getSongImg} src={getSongs.img} alt="Album cover"/>
                                            <CircularProgress className={styles.loader}/>
                                            <LinearProgress variant="determinate" value={getSongs.progress} className={styles.progressBar}/>
                                        </div>
                                        <p className={styles.getSongText}>{getSongs.text}</p>
                                        <p className={styles.getSongCaption}>{getSongs.caption}</p>
                                    </>
                                ) : null}
                            </div>
                        </div>
                        <div className={`centeredContainerHorz ${styles.container} ${stage === 2 ? styles.divIn : stage > 2 ? styles.divOut : styles.divOffscreen}`}>
                            <div className={styles.headerContainer}>
                                <h1 className={styles.header}><span>{">"}</span> Results</h1>
                                {stage === 2 ? (<>
                                    {this.renderRecords()}
                                </>) : null}
                            </div>
                        </div>
                    </div> 
                </>
                ) : (
                    <div className={styles.startWrapper}>
                        {checkingUser ? (
                            <div className={`centeredContainer`}>
                                <CircularProgress className={styles.loader}/>
                            </div>
                        ) : (
                            <div className="centeredContainer">
                                <BackToHome width="350px" align="left"/>
                                <h1 className={styles.title}>Music Data Analysis for Spotify</h1>
                                <a className={styles.connectButton} href="`https://accounts.spotify.com/authorize?client_id=a88ca20494e440e3b69ae54ea7840c45&response_type=code&redirect_uri=http://localhost:3000/projects/music-data-analysis-for-spotify&scope=playlist-read-private&code_challenge_method=S256&code_challenge=yQDgHrv-ndWb6DYGEuC44MlFfuMtU2jyYe6IodPvjmA`">
                                    Connect your Spotify account
                                </a>
                            </div>
                        )}
                    </div>
                    
                )}
            </>
        );
    }
}