import React from 'react';

import {WSConnection} from "../websocket";
import ColorPicker from "../ColorPicker";
import styles from "./style.module.scss";

export default class ColorControl extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  componentDidMount() {
    window.addEventListener('resize', () => {
      this.setState({
        width: window.innerWidth,
        height: window.innerHeight, 
      });
    });
  }

  render() {
    const {width, height} = this.state;
    return (<>
      <div className={styles.pageH2}>Use your finger in the circle below to change the color of the lampposts around the yard.</div>
      <div className={styles.colorPickerDiv}>
        <ColorPicker initialColor="#FF0203" size={Math.min(width * 0.8, height * 0.8)} onChange={(color => {if (color.hex.substr(1, 3) != 'NAN') WSConnection.sendMessage('"' + color.hex.substr(1) + '#"'); }) } />
      </div>
    </>);
  }
}