import { Popper, Zoom } from "@mui/material";
import { styled } from "@mui/styles";
import React from "react";

import styles from "./style.module.scss";

export default function Speechbox({open, anchorEl, onClose, children}) {
  const [arrowRef, setArrowRef] = React.useState(null);
  const ArrowPopper = styled(Popper)(({theme}) => ({
    '& > div': {
      marginTop: 2,
    },
    '& .MuiPopper-arrow': {
      top: 0,
      left: 0,
      marginTop: '-0.9em',
      width: '3em',
      height: '1em',
      '&::before': {
        borderWidth: '0 1em 1em 1em',
        borderColor: `transparent transparent ${theme.palette.background.paper} transparent`,
      },
    },
  }));
  const Arrow = styled('div')({
    position: 'absolute',
    fontSize: 7,
    width: '3em',
    height: '3em',
    '&::before': {
      content: '""',
      margin: 'auto',
      display: 'block',
      width: 0,
      height: 0,
      borderStyle: 'solid',
    },
  });
  console.log(arrowRef);
  return (
    <ArrowPopper 
      open={open} 
      anchorEl={anchorEl}
      onClose={onClose}
      modifiers={[{
        name: "arrow",
        enabled: true,
        options: {
          element: arrowRef,
        },
      }]}
    >
      <>
        <Arrow ref={(e) => {if (arrowRef === null && e !== null) {setArrowRef(e)}}} className="MuiPopper-arrow"/>
        <div className={styles.speechbox}>
          {children}
        </div>
      </>
    </ArrowPopper>
  );
}