import React from 'react';

import {WSConnection} from "../websocket";
import styles from "./style.module.scss";
import { KeyboardVoiceRounded, MicNoneRounded } from '@mui/icons-material';

export default class SkeletonControl extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      streaming: false,
      waveformPeak: 0,
      width: window.innerWidth,
      height: window.innerHeight, 
    };
  }

  componentDidMount() {
    WSConnection.initialize();
    WSConnection.bindListener(this);

    navigator.mediaDevices.getUserMedia({ 
      audio: true, 
      video: false 
    }).then((stream) => {
      const context = new AudioContext();
      const source = context.createMediaStreamSource(stream);
      const processor = context.createScriptProcessor(512, 1, 1);

      source.connect(processor);
      processor.connect(context.destination);;

      processor.onaudioprocess = (e) => {
        if (this.state.streaming) {
          const inputData = e.inputBuffer.getChannelData(0);
          if (inputData.length > 0) {
            this.setState({
              waveformPeak: inputData.reduce((acc, val) => Math.max(acc, Math.abs(val)), Math.abs(inputData[0])),
            });
            return WSConnection.sendMessage(inputData);
          }
        }
      };
    });

    window.addEventListener('resize', () => {
      this.setState({
        width: window.innerWidth,
        height: window.innerHeight, 
      });
    });
    window.addEventListener('mouseup', this.stopStreaming);
    window.addEventListener('touchend', this.stopStreaming);
  }

  startStreaming = () => {
    this.setState({
      streaming: true,
      waveformPeak: 0,
    });
  }

  stopStreaming = () => {
    this.setState({
      streaming: false,
      waveformPeak: 0,
    });
  }
  
  render() {
    const {streaming, waveformPeak, width} = this.state;

    let waveformSz = Math.abs(waveformPeak) * width;
    return (<>
      <div className={styles.pageH2}>Press the record button to talk as the pirate in real time!</div>
      <div className={styles.skeletonRecContainer}>
        <div className={styles.skeletonRecWaveform} style={{width: waveformSz, height: waveformSz}}></div>
        <button
          onMouseDown={this.startStreaming}
          onTouchCancel={this.stopStreaming}
          onMouseUp={this.stopStreaming}
          onTouchStart={this.startStreaming}
          onTouchEnd={this.stopStreaming}
          onLostPointerCapture={this.stopStreaming}
          onLostPointerCaptureCapture={this.stopStreaming}
          className={`${styles.skeletonRecButton}`}
        >
          <KeyboardVoiceRounded/>
        </button>
      </div>
    </>);
  }
}