import React from "react";
import {LocalOfferRounded, SelectAllRounded, CategoryRounded, LoopRounded} from '@mui/icons-material';
import {Button, Menu, MenuItem, FormControlLabel, Checkbox } from '@mui/material';
import { mdiSelectOff, mdiNutrition } from '@mdi/js';
import Icon from '@mdi/react';

export default class FilterMenu extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      anchorEl: null,
    };
  }

  render() {
    const {filters, changeFilters, icon, labelSingular, labelPlural} = this.props;
    const filtersOn = filters.filter(f => f.checked).length;
    const {anchorEl} = this.state;
    return (
      <>
      <Button aria-controls="simple-menu" aria-haspopup="true"
        onClick={(event) => this.setState({anchorEl: event.currentTarget})}
        startIcon={icon}
      >            
        Showing {filtersOn === filters.length ? "all ": ""}{filtersOn}{filtersOn < filters.length ? `/${filters.length}`: ""} {filtersOn === 1 ? labelSingular : labelPlural}
      </Button>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => this.setState({anchorEl: null})}
      >
        <MenuItem disabled>
          Choose {labelPlural} to show
        </MenuItem>
        <MenuItem>
          <Button 
            color="secondary"
            startIcon={<SelectAllRounded/>}
            onClick={() => changeFilters(filters.map(filter => {return {
              name: filter.name,
              checked: true,
            }}))}
          >
            SELECT ALL
          </Button>
          <Button 
            color="secondary"
            startIcon={<Icon path={mdiSelectOff} size={1}/>}
            onClick={() => changeFilters(filters.map(filter => {return {
              name: filter.name,
              checked: false,
            }}))}
          >
            SELECT NONE
          </Button>
        </MenuItem>
        {filters.map(filter => (
          <MenuItem>
            <FormControlLabel
              control={<Checkbox 
                checked={filter.checked}
                onClick={(() => {
                  const newFilters = filters.map(f => {
                    if (f.name === filter.name) {
                      return {
                        name: f.name,
                        checked: !f.checked
                      };
                    }
                    return f;
                  });
                  changeFilters(newFilters);
                })}/>}
              label={filter.name}
            />
          </MenuItem>
        ))}
      </Menu>
      </>
    );
  }
}