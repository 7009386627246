import React from 'react';
import { Link } from 'react-router-dom';
import { HomeRounded } from '@mui/icons-material'; 

import styles from './style.module.scss';

export default class BackToHome extends React.Component {
  render() {
    let { width, align, className, style, id, topLeft } = this.props;
    width = width || "auto";
    align = align || "center";
    style = style || {};
    style.width = width;
    style["justify-content"] = align;
    return (
      <div id={id ? id : ""} className={topLeft ? styles.topLeft : `${styles.flex} ${className ? className : ""}`} style={style}>
        <h2 className={styles.back}>
          <Link to="/">{"<"} <HomeRounded /></Link>
        </h2>
      </div>
    );
  }
}