import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import {withStyles} from "@mui/styles";

import { WSConnection } from "./websocket";

const theme = createTheme({
  palette: {
    primary: {
      light: '#e88f1a',
      main: '#e88f1a',
      dark: '#e88f1a',
      contrastText: '#000',
    },
    secondary: {
      light: '#e88f1a',
      main: '#e88f1a',
      dark: '#e88f1a',
      contrastText: '#e88f1a',
    },
  },
});

class ErrorWrapper extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      alert: null
    };
  }

  componentDidMount() {
    WSConnection.bindListener(this);
    WSConnection.initialize();

    const query = new URLSearchParams(window.location.search);
    if (query.get("msg") === "controlEnded") {
      this.setState({
        alert: {
          title: "Time's up!",
          message: "Your time to control is over. Hope you had fun!",
          button: "OK",
          buttonClick: () => {
            this.setState({alert: null});
            window.history.pushState({}, document.title, "/halloween");
          },
        },
      });
    }
  }

  errorThrown = (err) => {
    this.setState({
      alert: {
        title: "Error",
        message: err,
        button: "Refresh page",
        buttonClick: () => window.location.reload(),
      },
    });
  }

  render() {
    const {alert} = this.state;
    const {classes} = this.props;
    return (<ThemeProvider theme={theme}>
      <Dialog
        open={Boolean(alert)}
        onClose={() => window.location.reload()}
      >
        <DialogTitle className={classes.title}>{alert?.title}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {alert?.message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={alert?.buttonClick} color="primary">
            {alert?.button}
          </Button>
        </DialogActions>
      </Dialog>
      {this.props.children}
    </ThemeProvider>);
  }
}

export default withStyles(theme => ({
  title: {
    "& h2": {
      color: "#e88f1a",
    }
  },
}), {withTheme: true})(ErrorWrapper);