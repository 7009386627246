import React from 'react';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Snackbar, CircularProgress, Fab } from '@mui/material';
import MuiAlert from  '@mui/lab/Alert';
import { ThumbUpAltRounded, ShareRounded, ArrowUpwardRounded, MailOutlineRounded, LinkRounded } from '@mui/icons-material';
import Highlight from 'react-highlight.js';
import { Helmet } from 'react-helmet';
import ReactMarkdown from 'react-markdown';

import api from "api";
import {SetFavicon} from 'utils';
import Modal from 'components/Modal';
import NotFound from 'pages/NotFound';
import manifest from 'pages/blog/manifest';
import {Facebook, Twitter, Reddit} from 'components/Icons';

import style from './style.module.scss';

class BlogLoader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      postInfo: null,
      post: null,
      id: this.props.match.params.id,
      loading: true,
      backToTopVisible: false,
      liked: false,
      shared: false,
      likeCount: 0,
      shareCount: 0,
      loadingAll: true,
      loadingLike: false,
      loadingShare: false,
      shareModalOpen: false,
      notification: {
        open: false
      },
    };
    this.likePost = this.likePost.bind(this);
    this.shareModalClose = this.shareModalClose.bind(this);
  }

  uuid4() {
    let uuid = "",
      ii;
    for (ii = 0; ii < 32; ii += 1) {
      switch (ii) {
        case 8:
        case 20:
          uuid += "-";
          uuid += ((Math.random() * 16) | 0).toString(16);
          break;
        case 12:
          uuid += "-";
          uuid += "4";
          break;
        case 16:
          uuid += "-";
          uuid += ((Math.random() * 4) | 8).toString(16);
          break;
        default:
          uuid += ((Math.random() * 16) | 0).toString(16);
      }
    }
    return uuid;
  };

  async componentDidMount() {
    SetFavicon();

    //fix idiotic scroll-preserving crap
    window.scrollTo(0, 0);
    
    window.addEventListener('scroll', this.handleScroll);

    // get post info
    const { id } = this.state;
    var postInfo = null;
    for (var i = 0; i < manifest.length; i++) {
      if (manifest[i].file === id) {
        try {
          //App = require(`pages/blog/posts/${manifest[i].file}`).default;
          postInfo = manifest[i];
        }
        catch (e) {}
      }
    }
    if (postInfo === null) {
      this.setState({loading: false});
      return;
    }
    this.setState({ postInfo });

    const text = (await fetch(require.context('../../../', true)(`./pages/blog/posts/${postInfo.file}.md`))).text();
    this.setState({
      post: text,
      loading: false,
    });

    //get this person's uuid
    let uuid = window.localStorage.getItem("uuid");
    if (!uuid) {
      uuid = this.uuid4();
      window.localStorage.setItem("uuid", uuid);
    }
    this.setState({
      uuid,
    });

    //call api
    const {json} = await api(`blogs?id=${id}`);
    if (json) {
      const {Likes, LikedBy, Shares, SharedBy} = json;
      this.setState({
        liked: LikedBy.includes(uuid),
        shared: SharedBy.includes(uuid),
        likeCount: Likes,
        shareCount: Shares,
        loadingAll: false,
      });
    }
  }

  scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }

  handleScroll = (event) => {
    const target = event.target.body;
    if ((window.scrollY + 1000) >= target.scrollHeight - 400) {
      this.setState({backToTopVisible: true});
    }
    else {
      this.setState({backToTopVisible: false});
    }
  }

  showNotification(props) {
    const {message, type} = props;
    this.setState({
      notification: {
        message,
        type,
        open: true,
        onClose: () => {
          this.setState({notification:{open: false}});
        },
      }
    });
  };

  async likePost() {
    this.setState({loadingLike: true});

    const { id, liked, uuid, likeCount } = this.state;
    const newState = !liked;
    const json = await (await fetch("https://api.jothedev.com/prod/blogs", {
      method: newState ? 'POST' : 'DELETE',
      body: JSON.stringify({
        id,
        type: "like",
        uuid,
      })
    })).json();
    if (json?.success) {
      this.setState({
        liked: newState,
        likeCount: newState ? likeCount + 1 : likeCount - 1,
        loadingLike: false,
      });
    }
    else {
      //throw error
      this.showNotification({
        message: "There was an error processing your like. Try again?",
        type: 'error',
      });
      this.setState({loadingLike: false});
    }
  }
  
  shareModalClose() {
    this.setState({
      shareModalOpen: false,
    });
  }

  async interactedWithShareModal() {
    if (this.state.shared === false) {
      //mark as shared
      this.setState({loadingShare: true});
      const { id, uuid } = this.state;
      const json = await (await fetch("https://api.jothedev.com/prod/blogs", {
        method: 'POST',
        body: JSON.stringify({
          id,
          type: "share",
          uuid,
        })
      })).json();
      if (json?.success) {
        this.setState({
          shared: true,
          shareCount: this.state.shareCount + 1,
          loadingShare: false,
        });
      }
      else {
        //throw error
        this.showNotification({
          message: "There was an error recording your share. Try again?",
          type: 'error',
        });
        this.setState({loadingLike: false});
      }
    }
  }

  render() {
    const { id, post, postInfo: pi, loading, backToTopVisible, liked, shared, likeCount, shareCount, loadingAll, loadingLike, loadingShare, shareModalOpen, notification } = this.state;
    const postInfo = pi || {
      title: "Loading...",
      image: "loading.png",
      imageAlt: "Loading...",
      date: "Loading...",
      author: "Loading...",
      background: "#000",
    };
    let trueLikeCount = liked ? likeCount - 1 : likeCount;
    let trueShareCount = shared ? shareCount - 1 : shareCount;
    
    var master = require.context('../', true);
    let image;
    try {
      image = master(`./images/${postInfo.image}`);
    }
    catch { }

    return (!loading && post === null ? (<NotFound />) : (
      <div style={{background: postInfo.background}}>
        <Helmet title={postInfo.title}/>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={notification.open}
          autoHideDuration={6000}
          onClose={notification.onClose}
        >
          <MuiAlert elevation={6} variant="filled" onClose={notification.onClose} severity={notification.type}>
            {notification.message}
          </MuiAlert>
        </Snackbar>
        <div className={style.topbar}>
          <span className={style.topbarItem}>Jo the Dev</span>
          <Link to="/" className={style.topbarLink}><span role="img" aria-label="Home emoji">🏠</span> Home</Link>
          <Link to="/blog" className={style.topbarLink}><span role="img" aria-label="Scroll emoji">📜</span> Blog</Link>
          <Link to="/projects" className={style.topbarLink}><span role="img" aria-label="Test tube emoji">🧪</span> Projects</Link>
          <Link to="/about" className={style.topbarLink}><span role="img" aria-label="Male developer emoji">👨‍💻</span> About</Link>
        </div>
        <div style={{backgroundImage: `url(${image})`}} className={style.headerImage}>
          <div className={style.header} >
            <h1 className={style.title}>{postInfo.title}</h1>
            <img src={image} className={style.spacerImage} alt={postInfo.imageAlt}/>
          </div>
        </div>
        <div className={style.post}>
          <div className={style.authorDate}>
            Posted {postInfo.date} by <Link to="/about">{postInfo.author}</Link>
          </div>
          <div className={style.postContent}>
            {loading ? <div className={style.loadingDiv}/> : 
            <ReactMarkdown components={{
              code: ({node, ...props}) => props?.className ? 
                <Highlight language={props.className.replace("language-", "")} className={style.codeBlock}>{props.children}</Highlight> :
                props?.inline ? <code className={style.inlineCode}>{props.children}</code> :
                <code className={style.codeBlock}>{props.children}</code>,
              img: ({node, ...props}) => <img src={master(`./images/${props?.src}`)} alt={props?.alt}/>,
            }}>{post}</ReactMarkdown>}
          </div>
        </div>
        <div className={style.footer}>
          <div className={style.footerContent}>
            <div className={style.footerFrom}>From <Link to="/" className={style.footerName}>jothedev.com</Link></div>
            <div className={style.footerThanks}>Thanks for reading!</div>
            {loadingAll ? <CircularProgress /> : (<>
              <div className={`${style.footerLink} ${liked ? style.footerLinkHighlight : ""}`} onClick={this.likePost}>
                <div>
                  {loadingLike ? <CircularProgress /> : <ThumbUpAltRounded />}<span>{liked && trueLikeCount > 0 ? "You & " : liked ? "You " : ""}{(trueLikeCount >= 1 && liked) || !liked ? trueLikeCount : ""} {liked && trueLikeCount > 1 ? "others " : liked && trueLikeCount === 1 ? "other " : ""}liked</span>
                </div>
              </div>
              <div className={`${style.footerLink} ${shared ? style.footerLinkHighlight : ""}`} onClick={(() => this.setState({shareModalOpen: shareModalOpen ? false : true}))}>
                <div>
                  {loadingShare ? <CircularProgress /> : <ShareRounded />}<span>{shared && trueShareCount > 0 ? "You & " : shared ? "You " : ""}{(trueShareCount >= 1 && shared) || !shared ? trueShareCount : ""} {shared && trueShareCount > 1 ? "others " : shared && trueShareCount === 1 ? "other " : ""}shared</span>
                </div>
              </div>
              <Modal
                  title="Share this post"
                  visible={shareModalOpen}
                  buttons={[
                    {
                      text: "Cancel",
                      onClick: this.shareModalClose,
                    }
                  ]}
                  onClose={this.shareModalClose}
                >
                  <div className={style.shareModal}>
                    <div className={style.shareModalGrid}>
                      <span className={style.shareModalLink1} onClick={() => {
                        window.open(`mailto:?subject=${postInfo.title.replace(/ /g, '%20')}&body=https://jothedev.com/blog/${id}`);
                        this.interactedWithShareModal();
                      }}>
                        <div className={style.shareModalLinkIcon}><MailOutlineRounded /></div>
                        <div className={style.shareModalLinkName}>Email</div>
                      </span>
                      <span className={style.shareModalLink2} onClick={() => {
                        navigator.clipboard.writeText(`https://jothedev.com/blog/${id}`);
                        this.interactedWithShareModal();
                      }}>
                        <div className={style.shareModalLinkIcon}><LinkRounded /></div>
                        <div className={style.shareModalLinkName}>Copy Link</div>
                      </span>
                      <span className={style.shareModalLink3} onClick={() => {
                        window.open(`https://www.facebook.com/sharer/sharer.php?kid_directed_site=0&sdk=joey&u=https://jothedev.com/blog/${id}&display=popup&ref=plugin&src=share_button`, '_blank');
                        this.interactedWithShareModal();
                      }}>
                        <div className={style.shareModalLinkIcon}><Facebook /></div>
                        <div className={style.shareModalLinkName}>Facebook</div>
                      </span>
                      <span className={style.shareModalLink4} onClick={() => {
                        window.open(`https://twitter.com/intent/tweet?text=Check out this article from Jo the Dev:&url=https://jothedev.com/blog/${id}`, '_blank');
                        this.interactedWithShareModal();
                      }}>
                        <div className={style.shareModalLinkIcon}><Twitter /></div>
                        <div className={style.shareModalLinkName}>Twitter</div>
                      </span>
                      <span className={style.shareModalLink5} onClick={() => {
                        window.open(`http://www.reddit.com/submit?url=https://jothedev.com/blog/${id}&title=${postInfo.title} - Jo the Dev`, '_blank');
                        this.interactedWithShareModal();
                      }}>
                        <div className={style.shareModalLinkIcon}><Reddit /></div>
                        <div className={style.shareModalLinkName}>Reddit</div>
                      </span>
                    </div>
                    <div className={style.shareModalLinkBox}>
                      <label>Link: </label>
                      <input type="text" name="link" className={style.shareModalBox} readOnly value={`https://jothedev.com/blog/${id}`} onFocus={() => this.interactedWithShareModal()}/>
                    </div>
                  </div>
                </Modal>
            </>)}
          </div>
        </div>
        <div className={backToTopVisible ? style.buttonTopVisible : style.buttonTopHidden}>
          <Fab onClick={this.scrollToTop}>
            <ArrowUpwardRounded />
          </Fab>
        </div>
      </div>
    ))
  }
}
export default withRouter(BlogLoader);