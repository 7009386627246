import React from "react";
import { Link } from "react-router-dom";
import { ChevronRight } from "@mui/icons-material";

import { WSConnection } from "../websocket";
import { queueStatus, BackButton } from "../helpers";
import Diagram from "../assets/SkeletonChoice.svg";
import C1 from "../assets/NumberCircle-01.svg";
import C2 from "../assets/NumberCircle-02.svg";
import C3 from "../assets/NumberCircle-03.svg";
import styles from "./style.module.scss";

const Queues = [
  "Skeleton1",
  "Skeleton2",
  "Skeleton3",
];

export default class SkeletonPicker extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      queues: []
    };
  }

  componentDidMount() {
    WSConnection.initialize();
    WSConnection.bindListener(this);
  
    WSConnection.sendMessage({
      action: "leaveQueue",
    });
  }

  wsOpened = () => {
    WSConnection.sendMessage({
      action: "leaveQueue",
    });
  }

  messageReceived = (msg) => {
    if (msg && msg.length > 0) {
      this.setState({
        queues: msg.filter(queue => Queues.includes(queue.name)),
      });
    }
  }

  errorThrown(err) {

  }

  render() {
    const {queues} = this.state;

    const genButton = (queueName, queueNameFormatted, imgSrc, imgAlt) => {
      return (<>
        <Link to={`/halloween/skeleton?name=${queueName}`}>
          <div className={styles.skeletonPickerButton}>
            <img src={imgSrc} alt={imgAlt}/>
            <div className={styles.buttonMiddle}>
              <div className={styles.buttonTitle}>{queueNameFormatted}</div>
              {queueStatus(queues, queueName)}
            </div>
            <ChevronRight/>
          </div>
        </Link>
      </>);
    };

    return (
      <>
        <div className={styles.skeletonPickerContainer}>
          <BackButton/>
          <h1 className={styles.skeletonPickerTitle}>Choose a Skeleton to Control:</h1>
          <img src={Diagram}/>
          {genButton("Skeleton1", "Lower Graveyard Skeleton", C1, "#1")}
          {genButton("Skeleton2", "Lamppost Skeleton", C2, "#2")}
          {genButton("Skeleton3", "Driveway Skeleton", C3, "#3")}
        </div>
      </>
    );
  }
}