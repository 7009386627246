const posts = [
    {
        title: "How to host a PHP server with Docker (actually simple)",
        file: "host-a-php-server-with-docker",
        image: "docker-php.png",
        imageAlt: "docker logo plus php logo",
        date: "03/09/2020",
        author: "Jo the Dev",
        snippet: "I was frustrated with all the tutorials on how to get a PHP server running with Docker, so I made my own.",
        background: "#6682b7",
        color: "#fff",
    },
    {
        title: "Using Lambdas, AWS Cognito and Stripe on Wix (C#)",
        file: "using-lambdas-aws-cognito-and-stripe-on-wix",
        image: "cognito-stripe-wix.png",
        imageAlt: "aws cognito logo plus stripe logo plus wix logo",
        date: "02/19/2020",
        author: "Jo the Dev",
        snippet: "Using AWS Lambdas, AWS Cognito and the Stripe API we can build a functioning Wix website complete with logins, account info and subscriptions.",
        background: "#fff",
        color: "#000",
    },
    {
        title: "Introducing RandomDash – Get Random Food from DoorDash!",
        file: "introducing-randomdash",
        imageAlt: "the random dash logo",
        image: "randomDash.png",
        date: "02/03/2020",
        author: "Jo the Dev",
        snippet: "Can decide where the fetch to eat? And then when you do, you can't decide what the fetch to eat? Let DoorDash solve all your first world problems!",
        background: "#000",
        color: "#fff",
    },
    {
        title: "Stream Netflix in your C# program",
        file: "stream-netflix-in-your-c-sharp-program",
        image: "streamNetflix.png",
        imageAlt: "chromium logo plus netflix logo",
        date: "10/09/2019",
        author: "Jo the Dev",
        snippet: "You can easily install CefSharp to integrate a browser into your WinForms or WPF program. But what you don't know is that it takes 3 million extra steps to get it to play Netflix or some other streaming service.",
        background: "#000",
        color: "#fff",
    }
]
export default posts;