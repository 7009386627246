import React, { Component } from 'react';
import { Button } from '@mui/material';
import { CloseOutlined } from '@mui/icons-material';

import style from './style.module.scss';

class Modal extends Component {
  constructor (props) {
    super(props);
    this.formatButtons = this.formatButtons.bind(this);
  }
  
  componentDidMount() {

  }

  formatButtons(buttons) {
    if (buttons) {
      const { buttonStyle, buttonClass } = this.props;
      let layout = [];
      for (let i = 0; i < buttons.length; i++) {
        const button = buttons[i];
        const { text, onClick } = button;
        const primary = i === buttons.length - 1 ? true : false;
        layout.push(
          <span className={`${buttonClass ? buttonClass : ""}`} style={buttonStyle ? buttonStyle : null}>
            <Button variant="contained" className={primary ? style.buttonPrimary : style.buttonSecondary} onClick={onClick}>{text}</Button>
          </span>
        );
      }
      return layout;
    }
    else return null;
  }
  
  render() {
    const { visible, title, titleStyle, childStyle, titleClass, childClass, className, buttons, onClose} = this.props;
    const preventClose = this.props.preventClose || false;
    return (
      <>
        <div className={`${style.modalDarkbox} ${visible ? style.darkboxVisible : style.darkboxHidden}`} onClick={() => {
          if (!preventClose) {
            onClose();
          }
        }}>
        </div>
        <div className={`${style.modal} ${className ? className : ""} ${visible ? style.modalVisible : style.modalHidden}`}>
          <div className={`${style.title} ${titleClass ? titleClass : ""}`} style={titleStyle ? titleStyle : null}>
            {title}
            <span className={style.x} onClick={() => {
              if (!preventClose) {
                onClose();
              }
            }}>
              <CloseOutlined />
            </span>
          </div>
          <hr className={style.hr} />
          <div className={`${style.child} ${childClass ? childClass : ""}`} style={childStyle ? childStyle : null}>
            {this.props.children}
          </div>
          <div className={style.buttonsWrapper}>
            {this.formatButtons(buttons)}
          </div>
        </div>
      </>
    );
  }
}

export default Modal;