import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import { IconButton, Tooltip, Snackbar } from '@mui/material';
import { PauseCircleOutlineRounded, PlayCircleOutlineRounded, ReplayRounded, ArrowBackRounded, EmailRounded } from '@mui/icons-material';
import Timer from 'react-compound-timer';
import useWindowSize from 'react-use/lib/useWindowSize'
import Confetti from 'react-confetti'

import Modal from 'components/Modal';
import { GoogleMaps } from 'components/Icons';
import {rand} from "utils";
import Item from './Item';
import challenges from '../data/challenges.json';
import schema from '../data/schema.json';
import bell from './bell.mp3';
import styles from './style.module.scss';

const { width, height } = useWindowSize();

export default class PlayStreetViewBingo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      width: window.innerWidth,
      height: window.innerHeight,
      win: false,
      lose: false,
      score: 0,
      time: -1,
      itemCount: 0,
      redirect: false,
    };
  }

  componentDidMount() {
    this.reload();

    window.addEventListener("resize", () => {
      this.setState({
        width: window.innerWidth,
        height: window.innerHeight
      });
    });
  }

  reload = () => {
    const settings = JSON.parse(window.localStorage.getItem("settings"));
    console.log(settings);
    if (!settings) {
      return window.location.assign("/projects/street-view-bingo");
    }
    const {itemCount, customItemsOnly, customItems, timeLimit} = settings;

    let items = []; 
    //generate the items
    if (customItems) {
      let cust = customItems.split("\n").filter(custItem => custItem.replace(/ /g, "") !== "");
      if (customItemsOnly) {
        if (cust && cust.length > 0) {
          const goTo = Math.min(cust.length, itemCount);
          for (let i = 0; i < goTo; i++) {
            const index = rand(0, cust.length - 1);
            const challenge = cust[index];
            items.push(challenge);
            cust.splice(index, 1);
          }
        }
      }
      else {
        const numOfCust = Math.min(cust.length, rand(4, 8));
        //default
        for (let i = 0; i < itemCount - numOfCust; i++) {
          let challenge = this.generateItem();
          while (items.includes(challenge)) {
            challenge = this.generateItem();
          }
    
          items.push(challenge);
        }
        //custom
        for (let i = 0; i < numOfCust; i++) {
          const index = rand(0, cust.length - 1);
          const challenge = cust[index];
          items.push(challenge);
          cust.splice(index, 1);
        }
        //shuffle items
        items = this.shuffle(items);
      } 
    }
    else {
      for (let i = 0; i < itemCount; i++) {
        var challenge = this.generateItem();
        while (items.includes(challenge)) {
          challenge = this.generateItem();
        }
  
        items.push(challenge);
      }
    }
    this.setState({      
      time: timeLimit,
      itemCount,
      items,
      win: false,
      lose: false,
      score: 0,
    });
  }

  shuffle(a) {
    for (let i = a.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [a[i], a[j]] = [a[j], a[i]];
    }
    return a;
  }

  generateItem = () => {
    return challenges[rand(0, challenges.length - 1)].split(' ').map(word => {
      if (word.startsWith('$')) {
        var schemaType = word.substring(1);
        var addA = false;
        if (schemaType.startsWith('a_')) {
          addA = true;
          schemaType = schemaType.substring(2);
        }

        if (schemaType.startsWith('random')) {
          //$random_2_10
          var split = schemaType.split('_');
          return rand(parseInt(split[1]),parseInt(split[2]));
        }

        var toAdd = schema[schemaType][rand(0, schema[schemaType].length - 1)];
        if (addA) {
          if (toAdd.startsWith('a') || toAdd.startsWith('e') || toAdd.startsWith('i') || toAdd.startsWith('o') || toAdd.startsWith('u')) {
            toAdd = "an " + toAdd;
          } else {
            toAdd = "a " + toAdd;
          }
        }
        
        return toAdd;
      } else {
        return word;
      }
    }).join(" ");
  }
  
  render() {
    const {items, itemCount, width, height, win, lose, score, time, redirect} = this.state;
    return (<>
      <Confetti
        width={width}
        height={height}
        run={win}
        style={{opacity: win ? 1 : 0}}
        className={styles.confetti}
      />
      <audio id="audio">
        <source src={bell} type="audio/mpeg" />
      </audio>
      {redirect ? <Redirect to="/projects/street-view-bingo"/> : null}
      <div className={styles.GameApp}>
        <div className={styles.timer}>
          {time > 0 ? <Timer
            initialTime={time * 60 * 1000 + 1000}
            className={styles.timer}
            direction="backward"
            formatValue={(value) => `${(value < 10 ? `0${value}` : value)}`}
            checkpoints={[
              {
                time: 0,
                callback: () => {
                  this.setState({lose: true});
                  document.getElementById("audio").play();
                },
              }
            ]}
          >
            {({ start, pause, reset, getTimerState, getTime }) => (
              <>
                {win ? pause() : null}
                <Tooltip title="Back">
                  <Link to="/projects/street-view-bingo">
                    <IconButton className={styles.btn_back}>
                      <ArrowBackRounded />
                    </IconButton>
                  </Link>
                </Tooltip>
                {(getTimerState() === 'PLAYING' ? (
                  <Tooltip title="Pause">
                    <IconButton className={styles.btn_toggle} onClick={pause}>
                      <PauseCircleOutlineRounded />
                    </IconButton>
                  </Tooltip>) : (
                  <Tooltip title="Play">
                    <IconButton className={styles.btn_toggle} onClick={start}>
                      <PlayCircleOutlineRounded />
                    </IconButton>
                  </Tooltip>
                  )
                )}
                <div className={styles.timerText}>
                  {(getTime() >= 60 * 60 * 1000 ? (<><Timer.Hours className={styles.timer} />:</>) : null)}
                  <Timer.Minutes className={styles.timer}/>:
                  <Timer.Seconds className={styles.timer}/>
                </div>
                <Tooltip title="Reset">
                  <IconButton className={styles.btn_toggle} onClick={reset}>
                    <ReplayRounded />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Open Google Maps" className={styles.btn_gmaps_tooltip} >
                  <a href="https://maps.google.com" target="_blank" rel="noopener noreferrer">
                    <IconButton className={styles.btn_gmaps} onClick={reset}>
                      <GoogleMaps fill="white"/>
                    </IconButton>
                  </a>
                </Tooltip>
                <Modal
                  visible={win || lose}
                  onClose={() => this.setState({win: false, lose: false})}
                  title={win ? "You win!" : "Time's up!"}
                  buttons={[
                    {
                      text: "New Game",
                      onClick: () => this.reload(),
                    },
                    {
                      text: "Back",
                      onClick: () => this.setState({redirect: true}),
                    }
                  ]}
                >
                  {win ? <>
                    <h2 className={styles.winScore}>Your score: {score}/{itemCount}</h2>
                    <h2 className={styles.winTime}>Your time: <Timer.Minutes className={styles.timer}/>:<Timer.Seconds className={styles.timer}/></h2>
                  </> : <h2 className={styles.winScore}>Rats! You ran out of time.</h2>}
                </Modal>
              </>
            )}
          </Timer> : null}
        </div>
        <hr />
        <div className={styles.lookingFor}>
          <h2 className={styles.lookingFor}><u>You are looking for:</u></h2>
        </div>
        <div className={styles.items}>
          {items.map(i => <Item text={i} key={i} onChange={(value) => this.setState({
            score: value ? this.state.score + 1 : this.state.score - 1,
            win: (value ? this.state.score + 1 : this.state.score - 1) === this.state.itemCount,
          })}/>)}
        </div>
        <hr />
        <h1 style={{margin: 0}}>Score: <span className={styles.score}>{score}</span>/{itemCount}</h1>
      </div>
    </>);
  }
}