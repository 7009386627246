const manifest = [
  /*{
    name: "Blue Tiles",
    description: "Blue Tiles is a game of strategy where you pick up tiles in order to form a mosaic, all while trying to win the most points.",
    bg_color: "#fff",
    color: "#4276B3",
    folder: "BlueTiles",
    favicon: "/favicon.png",
    app: "/index.js",
    url: "blue-tiles",
    date: "07/03/2020",
  },*/
  /*{
    name: "FowlFigures",
    description: "Get statistics on our chicken coop - egg counts, chicken activity and more!",
    bg_color: "#fff",
    color: "#6D4C3E",
    folder: "FowlFigures",
    favicon: "/favicon.png",
    app: "/index.js",
    url: "fowl-figures",
    date: "07/26/2020",
  },*/
  /*{
    name: "SeatGuru",
    description: "The smart seating chart generator.™",
    bg_color: "#259783",
    color: "#fff",
    folder: "SeatGuru",
    favicon: "/favicon.png",
    app: "/index.js",
    url: "seatguru",
    date: "03/22/2021",
  },*/
  /*{
    name: "LUMON MDR Console",
    description: "Based on Apple TV+'s show \"Severance\".",
    bg_color: "#8712f6",
    color: "#fff",
    favicon: "/favicon.png",
    folder: "LumonMDRConsole",
    app: "/index.js",
    url: "lumon-mdr",
    date: "04/22/2022",
  },*/
  {
    name: "Test your CQ",
    description: "Play a short game to test your Cultural Intelligence (CQ). Made as a final project for my Communications class.",
    bg_color: "#8712f6",
    color: "#fff",
    favicon: "https://cq.jothedev.com/favicon.ico",
    url: "https://cq.jothedev.com",
    date: "12/16/2021",
  },
  {
    name: "Picklescore",
    description: "Tired of disputing the score when playing Pickleball? Turn your laptop or phone into a scoreboard!",
    bg_color: "#fff",
    color: "#5ABF3D",
    folder: "Picklescore",
    favicon: "/favicon.png",
    app: "/index.js",
    url: "picklescore",
    date: "08/05/2021",
  },
  {
    name: "The Cheesecake Factory Decider",
    description: "The Cheesecake Factory has a lot of options on their menu, all of which are delicious. Break down the menu and decide what you want to order!",
    bg_color: "#72094A",
    color: "#DBB985", 
    folder: "CheesecakeFactory",
    favicon: "/favicon.png",
    app: "/index.js",
    url: "cheesecake-factory",
    date: "06/15/2021",
  },
  {
    name: "The Chameleon Expansion",
    description: "An expansion to the popular game \"The Chameleon\".",
    bg_color: "#259783",
    color: "#fff",
    folder: "ChameleonExpansion",
    favicon: "/favicon.png",
    app: "/index.js",
    url: "chameleon-expansion",
    date: "02/11/2021",
  },
  /*{
    name: "ChubbyEmu Title Generator",
    description: "Generates a fake ChubbyEmu YouTube title",
    bg_color: "#333",
    color: "#fff",
    folder: "ChubbyEmu",
    favicon: "/favicon.png",
    app: "/index.js",
    url: "chubbyemu-title-generator",
    date: "12/06/2020",
  },
  {
    name: "Music Data Analysis for Spotify",
    description: "Analyze the music you listen to on Spotify.",
    bg_color: "#333",
    color: "#fff",
    folder: "SpotifyDataAnalysis",
    favicon: "/favicon.png",
    app: "/index.js",
    url: "music-data-analysis-for-spotify",
    date: "09/16/2020",
  },*/
  /*{
    name: "MovieMute",
    description: "Windows app that automatically takes out the swearing of any movie. Coming soon!",
    bg_color: "#000",
    color: "#FFD700",
    folder: "MovieMute",
    favicon: "/favicon.png",
    app: "/index.js",
    url: "moviemute",
    date: "11/04/2020",
  },*/
  /*{
    name: "BookLookup",
    description: "Searches both Scholastic and Lexile to find any book's reading level. Barcode scanners supported!",
    bg_color: "#2ed175",
    color: "#fff",
    folder: "BookLookup",
    favicon: "/favicon.png",
    app: "/index.js",
    url: "book-lookup",
    date: "08/05/2020",
  },*/
  {
    name: "Street View Bingo",
    description: "Generates a random list of things for you to find in Google Street View before the timer runs out. Inspired by Tom Davies' Street View Bingo series.",
    bg_color: "#fff",
    color: "#e43b3b",
    folder: "StreetViewBingo",
    favicon: "/favicon.ico",
    app: "/index.js",
    url: "street-view-bingo",
    date: "04/28/2020",
    subPages: ["play", "credits"],
  }
];

export default manifest;