export default async function api(url, options = {}) {
  const json = await (await fetch(`https://api.jothedev.com/prod/${url}`, {
    ...options,
    method: options.method || 'GET',
    ...(options.body ? {body: JSON.stringify(options.body)} : {}),
  })).json();
  if (json?.success) {
    return {json};
  }
  return {errorMessage: json?.errorMessage || "Something went wrong with that request. Please try again."};
}