const WSConnection = {
  ws: null,
  listeners: [],
  isOpen: false,
  initialize(url) {
    if (WSConnection.ws === null) {
      WSConnection.ws = new WebSocket(url || "wss://websocket.jothedev.com");//"ws://localhost:8080");//
    }
    
    WSConnection.ws.onopen = () => {
      WSConnection.isOpen = true;
      WSConnection.listeners.forEach(listener => { if (listener.wsOpened) {listener.wsOpened()}});
    }

    WSConnection.ws.onmessage = (ev) => {
      const json = JSON.parse(ev.data);
      console.log(`ws onmessage: `, json);
      if (json?.errorMessage) {
        WSConnection.listeners.forEach(listener => { if (listener.errorThrown) {listener.errorThrown(json.errorMessage)}});
      }
      else if (json?.type === "ping") {
        WSConnection.sendMessage({type: "pong"});
      }
      else {
        WSConnection.listeners.forEach(listener => { if (listener.messageReceived) {listener.messageReceived(json)}});
      }
    };

    WSConnection.ws.onclose = () => {
      console.log(`WEBSOCKET CONNECTION CLOSED`);
      WSConnection.isOpen = false;
      WSConnection.listeners.forEach(listener => {if (listener.errorThrown) {listener.errorThrown(`The connection to our servers failed. Please try refreshing the page.`)}});
    };
  },
  bindListener(listener) {
    WSConnection.listeners.push(listener);
  },
  sendMessage(message) {
    if (WSConnection.ws.readyState === 1) {
      if (message instanceof Float32Array) {
        WSConnection.ws.send(message);
      }
      else if (message instanceof Object) {
        WSConnection.ws.send(JSON.stringify(message));
      }
      else {
        WSConnection.ws.send(message);
      }
    }
  },
}

exports.WSConnection = WSConnection;