import React from 'react';

import {WSConnection} from "../websocket";
import ColorPicker from "../ColorPicker";
import styles from "./style.module.scss";

export default class  extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  componentDidMount() {
    window.addEventListener('resize', () => {
      this.setState({
        width: window.innerWidth,
        height: window.innerHeight, 
      });
    });
  }
  
  render() {
    const {width, height} = this.state;
    return (<>
      <div className={styles.pageH2}>Use your finger in the circle below to circle the tree with white lanterns.</div>
      <div className={styles.treeColorPickerDiv}>
        <ColorPicker treeColor size={Math.min(width * 0.8, height * 0.8)} onChange={color => {
          if (!isNaN(color.hsl.h)) {
            const index = Math.floor(color.hsl.h / 18.94) + 10; // +10 so we have two digits
            WSConnection.sendMessage(`"TL:${index} #"`)
          }
        }} />
      </div>
    </>);
  }
}