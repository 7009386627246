import React from "react";
import { CircularProgress } from "@mui/material";
import { Link, useHistory } from "react-router-dom";
import { ChevronLeft } from "@mui/icons-material";

import styles from "./style.module.scss";

export function formatTime(time) {
  if (time < 60) {
    return `${time} sec`;
  }
  if (time < 3600) {
    return `${Math.floor(time / 60)} min ${time % 60} sec`;
  }
  const min = Math.floor((time % 3600) / 60);
  return `${Math.floor(time / 3600)} hr ${min} min ${time % 60} sec`;
}

export function queueStatus (queues, name) {
  let queue = queues?.filter(q => q.name === name);
  if (queue?.length === 0) {
    return (<div className={styles.buttonStatus}>
      <CircularProgress className={styles.buttonQueueLoading}/>
      <span>Loading wait times...</span>
    </div>);
  }
  queue = queue[0];
  return (<div className={styles.buttonStatus}>
    {queue.totalInLine === 0 ? 
      <span className={styles.queueAvailable}>Available</span> : 
      <span className={styles.queueWait}>{formatTime(queue.waitTime)} wait</span>}
    &nbsp;&bull;&nbsp;<span className={styles.queueCount}>{queue.totalInLine} in line</span>
  </div>);
}

export function rand(lower, upper) {
  return Math.random() * upper + lower;
}

export function randInt(lower, upper) {
  return Math.floor(rand(lower, upper));
}

export function BackButton(props) {
  let history = useHistory();

  return (<>
    <div style={{display: "inline-block"}}>
      <button className={`${styles.backButton} ${props?.mini ? styles.backButtonMini : ""}`} onClick={() => history.goBack()}>
        <ChevronLeft/>
        {props?.mini ? null : <span>Back</span>}
      </button>
    </div>
  </>)
}