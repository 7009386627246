import {fabric} from "fabric";

class FabricCanvasTool {
  constructor(canvas) {
    this._canvas = canvas;
  }

  configureCanvas(props) {}

  doMouseUp(event) {}

  doMouseDown(event) {}

  doMouseMove(event) {

  }

  doMouseOut(event) {}
}

export default class Pencil extends FabricCanvasTool {
  configureCanvas(props) {
    this._canvas.isDrawingMode = true;
    this._canvas.freeDrawingBrush.width = props.lineWidth;
    this._canvas.freeDrawingBrush.color = props.lineColor;
    /*this._canvas.freeDrawingBrush.shadow = new fabric.Shadow({
      blur: 4,
      offsetX: 5,
      offsetY: 5,
      affectStroke: true,
      color: "black",
    });*/
  }
}