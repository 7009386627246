import React from 'react';
import { Card, CircularProgress } from '@mui/material';
import { Masonry } from '@mui/lab';

import BackToHome from 'components/BackToHome';
import style from './style.module.scss';

export default class BookWizard extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      results: [],
      loadStatus: [],
    };

    this.execute = this.execute.bind(this);
    this.generateResults = this.generateResults.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    /*this.generateResults([{
      DRA_Level_f: 50,
      DRA_Level_s: "50",
      English_Guided_Reading_Level_s: "V",
      ISBN13_s: "9780440414803",
      ISBN_13_s: "9780440414803",
      OurPrice_f: 5.99,
      ProductType_s: "Books | Individual Titles | Paperback Book",
      authors_s: "[{\"name\":\"Louis Sachar\",\"url\":\"/content/scholastic/contributors/louis-sachar\"}]",
      availabilityText_s: "From the Teacher Store",
      cartButtonText_s: "Add to Cart",
      englishLexileLevel_s: "660L",
      format: "Paperback Book",
      grade_ss_dp: ["6-8|Grades 6-8"],
      grades_ss: ["scholastic:grade/grade-6", "scholastic:grade/grade-7", "scholastic:grade/grade-8"],
      id: "PCD_WORK_1008599",
      imageLinks_ss: ["https://www.scholastic.com/content5/media/products/03/9780440414803_lg.jpg", "https://www.scholastic.com/content5/media/products/03/9780440414803_mres.jpg", "https://www.scholastic.com/content5/media/products/03/9780440414803_sm.jpg", "https://www.scholastic.com/content5/media/products/03/9780440414803_xlg.jpg", "https://www.scholastic.com/content5/media/products/03/9780440414803_xsm.jpg"],
      language_s: "English",
      searchImage: "https://www.scholastic.com/content5/media/products/03/9780440414803_xlg.jpg",
      stockAvailability: true,
      teachers_ss_dp: ["books|Books"],
      title_s: "Holes",
      type_s: "work",
      wishListErrorMessage_s: "The wishlist is temporarily unavailable. Please check back soon.",
      workTitle_s: "Holes",
      workURL_s: "/content/scholastic/books2/holes-by-louis-sachar",
    }]);*/
  }

  generateResults = (json, json2) => {
    let arr1 = [];
    let arr2 = [];
    json.forEach(result => {
      const authorJson = JSON.parse(result.authors_s);
      const authors = authorJson.map(author => {return author.name}).join(', ');
      let grl = result.English_Guided_Reading_Level_s;
      if (!grl) {
        let lexile = result.englishLexileLevel_s;
        if (lexile) {
          lexile = lexile.replace(/([A-z])+/g, "");
          if (lexile !== "") {
            if (lexile <= 220) {
              grl = "A, B or C";
            }
            else {
              const index = (lexile - 220) / 50 + 68;
              if (index > 90) {
                grl = "Z+";
              }
              else {
                grl = String.fromCharCode(index);
              }
            }
          }
        }
        
      }
      arr1.push(
        <Card className={style.card} style={{backgroundColor: "#282c34"}}>
          <div className={style.imgContainer}>
            <a href={`https://www.scholastic.com${result.workURL_s}`} target="_blank" rel="noopener noreferrer">
              <img src={result.searchImage} className={style.img} alt={`${result.title_s} Cover`}/>
            </a>
          </div>
          <div className={style.infoContainer}>
            <a href={`https://www.scholastic.com${result.workURL_s}`} target="_blank" rel="noopener noreferrer">
              <h2>{result.title_s}</h2>
            </a>
            <h3>{authors}</h3>
            <div className={style.infoItem}>Grades: <span className={style.highlight}>{result.grade_ss_dp ? result.grade_ss_dp[0].split('|')[0] : "Unknown"}</span></div>
            <div className={style.infoItem}>Guided Reading Level: <span className={style.highlight}>{grl || "Unknown"}</span></div>
            <div className={style.infoItem}>LEXILE® MEASURE: <span className={style.highlight}>{result.englishLexileLevel_s || "Unknown"}</span></div>
            <div className={style.infoItem}>DRA Level: <span className={style.highlight}>{result.DRA_Level_s || "Unknown"}</span></div>
            <div className={style.infoItem}>ISBN: <span className={style.highlight}>{result.ISBN13_s ? result.ISBN13_s : result.ISBN_13_s ? result.ISBN_13_s : "Unknown"}</span></div>
          </div>
        </Card>
      );
    });
    json2.forEach(result => {
      let authors = result.authors.join(", ");
      //convert lexile to grl
      let grl = null;
      const lexile = result.lexile;
      if (lexile !== null && lexile !== undefined) {
        if (lexile <= 220) {
          grl = "A, B or C";
        }
        else {
          const index = (lexile - 220) / 50 + 68;
          if (index > 90) {
            grl = "Z+";
          }
          else {
            grl = String.fromCharCode(index);
          }
        }
      }
      arr2.push(
        <Card className={style.card} style={{backgroundColor: "#132c5e"}}>
          <div className={style.imgContainer}>
            <img src={result.published_works ? result.published_works[0].cover_art_url : ""} className={style.img} alt={`${result.title} Cover`}/>
          </div>
          <div className={style.infoContainer}>
            <h2>{result.title}</h2>
            <h3>{authors}</h3>
            <div className={style.infoItem}>Ages: <span className={style.highlight}>{result.max_age && result.min_age ? `${result.min_age} - ${result.max_age}` : "Unknown"}</span></div>
            <div className={style.infoItem}>Guided Reading Level: <span className={style.highlight}>{grl || "Unknown"}</span></div>
            <div className={style.infoItem}>LEXILE® MEASURE: <span className={style.highlight}>{lexile || "Unknown"}</span></div>
            <div className={style.infoItem}>ISBN: <span className={style.highlight}>{result.canonical_isbn || "Unknown"}</span></div>
          </div>
        </Card>
      );
    });
    let out = this.mixArrays(arr1, arr2);
    this.setState({
      results: out,
      input: ""
    });
  }

  mixArrays = (arr1, arr2) => {
    let out = [];
    for (let i = 0; i < arr1.length; i++) {
      out.push(arr1[i]);
      if (arr2.length > i) {
        out.push(arr2[i]);
      }
    }
    if (arr2.length > arr1.length) {
      for (let i = arr1.length; i < arr2.length; i++) {
        out.push(arr2[i]);
      }
    }
    return out;
  }

  onChange = params => {
    let barcode = params.target.value;
    this.setState({
      input: barcode
    });
    if (barcode.length >= 13 && !isNaN(barcode)) {
      this.execute(barcode);
    }
    else {
      
    }
  }

  onKeyUp = params => {
    if (params.keyCode === 13) {
      this.execute();
    }
  }

  execute = inBarcode => { 
    let barcode = inBarcode;
    if (barcode === undefined) {
      barcode = this.state.input;
    }
    else if (barcode.target) {
      barcode = this.state.input;
    }
    this.setState({
      query: barcode,
      results: [],
      loading: true,
      loadStatus: ['(1/2) Querying Scholastic...'],
    });
    const proxyurl = "https://jothedev-cors-anywhere.herokuapp.com/";
    fetch(`${proxyurl}https://www.scholastic.com/bin/scholastic/teachers/tchsearch?isBookWizard=true&pagePath=%2Fteachers%2Fbookwizard%2F&prefilter=books&rows=72&start=0&text=${barcode}`, {
      method: 'GET',
      headers: {
        "Referer": "https://www.scholastic.com/teachers/bookwizard/?search=1&filters=&prefilter=books&text=9780440414803",
        "User-Agent": "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_5) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/84.0.4147.105 Safari/537.36",
      }
    })
    .then(response => response.json())
    .then(json => {
      if (json) {
        if (json.solrDocumentList) {
          let { loadStatus } = this.state;
          loadStatus.push('(2/2) Querying Lexile...');
          this.setState({loadStatus});
          fetch(`${proxyurl}https://atlas-fab.lexile.com/free/search`, {
            method: 'POST',
            headers: {
              'Accept': 'application/json; version=1.0',
              'Content-Type': 'application/json',
              "Referer": "https://www.scholastic.com/teachers/bookwizard/?search=1&filters=&prefilter=books&text=9780440414803",
              "User-Agent": "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_5) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/84.0.4147.105 Safari/537.36",
            },
            body: JSON.stringify({"filters":{"language":"english"},"sort_by":"-score","term":barcode,"results_per_page":100,"page":1})
          })
          .then(response => response.json())
          .then(json2 => {
            this.setState({
              loading: false,
              searched: true,
              loadStatus: [],
            });
            console.log(json, json2);
            if (json2) {
              this.generateResults(json.solrDocumentList, json2.data.results);
            }
          })
        }
      }
    });
  }

  formatLoadStatus = arr => {
    let out = [];
    for (let i = 0; i < arr.length - 1; i++) {
      const status = arr[i];
      out.push(<h2 className={style.loadStatusDone}><span role="img" aria-label="check mark">✅</span> {status}</h2>);
    }
    const status = arr[arr.length - 1];
    out.push(<h2 className={style.loadStatus}>{status}</h2>);
    return out;
  }

  render () {
    const {results, input, query, loading, loadStatus, searched} = this.state;
    return (
      <div className="centeredContainerHorz">
        <div className={style.titleContainer}>
          <div style={{margin: "20px"}}><BackToHome align="left"/></div>
          <h1 className={style.mainTitle}>BookLookup <span role="img" aria-label="left-pointing magnifying glass">🔍</span></h1>
        </div>
        <h1 className={style.title}>Search for a book:</h1>
        <h2 className={style.subtitle}>(Or scan the book's barcode)</h2>
        <div className={style.searchContainer}>
          <input onChange={this.onChange} className={style.barcodeInput} value={input} onKeyUp={this.onKeyUp}/>
          <button className={style.search} onClick={this.execute}>Search</button>
        </div>
        <hr className={style.hr} />
        {searched && !loading ? (
          <h1>Results for: <span className={style.highlight}>{query}</span></h1>
        ) : null}
        {loading ? (
          <>
            <CircularProgress/>
            <h1>Loading...</h1>
            {this.formatLoadStatus(loadStatus)}
          </>
        ) : 
          results.length > 0 ? 
            (
              <Masonry columns={3} spacing={2}>
                {results}
              </Masonry> 
            ) : 
            searched ? (
              <h2 className={style.noResults}>No Results</h2>
            ) : (
              <h2 className={style.noResults}>Search above</h2>
            ) 
        }
      </div>
    )
  }
}

//